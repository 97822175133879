import { useState } from "react";
import { ButtonPrimary } from "../../../components/ctas";
import { PageHeader } from "../../../components/headers";
import { useAppContext } from "../../../contexts/app";
import { Scanner } from '@yudiel/react-qr-scanner';

const ButtonLink = (props) => {
	return <>
		<div>
			<ButtonPrimary {...props } className="tw-w-full tw-aspect-square tw-items-center tw-justify-center" default>
				{ props.children }
			</ButtonPrimary>
		</div>
	</>
}



export default function Home() {
	const { products } = useAppContext();

	const [qrValue, setQRValue] = useState(null);
	const handleQRCode = (result) => {
		if (result[0].rawValue) {
			setQRValue(result[0].rawValue);
		} else {
			alert('Invalid QR Code');
		}
	}


	return <>
		<div className="tw-space-y-30px tw-pb-60px">
			<PageHeader className="tw-w-full tw-text-center">
				Welcome
			</PageHeader>

			{/* <div className="tw-bg-red-600 tw-w-full tw-min-h-[50vh]">
				<Scanner onScan={(result) => handleQRCode(result)} />

				{ qrValue && <div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center">
					{ qrValue }
					<br />
					SERIAL: { qrValue.split('serial=')[1] }
				</div>}
			</div> */}
			<section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20px md:tw-gap-40px tw-max-w-700px tw-mx-auto md:tw-px-40px">
				<ButtonLink to="/dashboard/inventory">
					Inventory Tracker
				</ButtonLink>
				<ButtonLink to="/dashboard/weight-dims">
					Size &amp; Weight
				</ButtonLink>
				<ButtonLink to="/dashboard/qc">
					QC
				</ButtonLink>
				<ButtonLink to="/dashboard/qc/completed">
					QC Completed
				</ButtonLink>
				<ButtonLink to="/dashboard/qc/completed/serials">
					QC Serials
				</ButtonLink>

				<ButtonLink to="/dashboard/order-labels">
					Labels
				</ButtonLink>
			</section>
			<section className="tw-grid tw-grid-cols-1 tw-max-w-700px tw-text-center tw-mx-auto md:tw-px-40px [&_a_span]:tw-min-h-60px [&_a]:tw-flex [&_a]:tw-justify-center [&_a]:tw-items-center [&_a_span]:tw-flex [&_a_span]:tw-justify-center [&_a_span]:tw-items-center [&_a_span]:tw-aspect-auto [&_a_span]:tw-flex-grow [&_a_span]:!tw-bg-[#0162C3] [&_a_span]:!tw-text-white [&_a_span]hover:!tw-bg-black">
				<ButtonLink to="/fs" className="">
					FS
				</ButtonLink>
			</section>
		</div>
	</>
}
