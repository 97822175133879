import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AspectImage } from "../../../components/assets";
import { Button, ButtonLight } from "../../../components/ctas";
import { Field, Input, Label, IncludesSpeakers, NumberOfCameras, DynamicSelectorButtons } from "../../../components/forms";
import { useAppContext } from "../../../contexts/app";
import { useAuth } from "../../../hooks/useAuth";
import { removeUnderscore } from "../../dashboard/qc/page";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { generateUUID } from "../../../utils/global";
import { useParams } from "react-router-dom";
// import { formatUppercase } from "../../../utils/formatting";
import { fieldDataFSUnits } from "../data/fieldDataFSUnits.js";


// add all progress fields and set to 0
const initProgress= (data) => {
	return data.map((section) => {
		section.progress = 0.00;
		return section;
	});
}


export const FieldsetWrapperFS = ({title, children, id, className, values, setFields, fields}) => {
	const field = useRef();

	// update section based on section's value for 'Section'
	// const updateSectionField = (sectionName, value) => {
		// return fields?.map((section) => {
		// 	if (section.Section === sectionName) {
		// 		section.progress = value || 0;
		// 	}
		// 	return section;
		// });
	// }

	const listenToFormFields = () => {
		if (field.current) {
			const formFields = field.current?.querySelectorAll('input, select, textarea');
			let formFieldsWithValue = formFields.length ? Array.from(formFields).filter(field => field.value) : 0;
			formFields.forEach(field => {
				field.addEventListener('change', () => {
					field.addEventListener('blur', () => {

						// const temp = updateSectionField(title, formFieldsWithValue.length / formFields.length);

						// console.log(temp)
						// setValues({
						// 	...values,
						// 	temp
						// });

						console.log('blur', formFields.length, formFieldsWithValue.length);
						// using `values` identify how many fields have values OR see how many form fields within `field.current` have values out of formFields.length, then write to `progress` in `fields`
						// maybe... compare values to fields and update progress... hmm...
					});
				});
			});
		}
	}

	useLayoutEffect(() => {
		if (field.current) listenToFormFields();
	}, [field]);

	return <>
		<fieldset ref={field} id={ id || null }  className={`tw-flex tw-flex-col tw-w-full tw-space-y-15px tw-rounded-8px${ className ? ` ${ className }` : '' }`}>
			<legend className="tw-text-[2em] tw-uppercase tw-tracking-wider tw-w-full tw-pb-15px tw-relative -tw-mb-30px">
				{ title || "Group" }
			</legend>
			<div className="tw-flex tw-flex-col tw-w-full tw-space-y-15px tw-rounded-5px tw-overflow-clip tw-border-1px tw-border-white/12 tw-px-30px tw-py-30px tw-bg-white/5 tw-shadow-drop-b-lg">
				{ children }
			</div>
		</fieldset>
	</>
}

export const QCSubNav = ({ subNavData, fields }) => {
	return <>
		<ul className="tw-sticky tw-top-40px [&_a]:tw-flex [&_a]:tw-items-center [&_a]:tw-w-full [&_a]:tw-min-h-30px tw-uppercase tw-leading-none tw-text-[.8em] tw-min-w-150px tw-divide-y-1px tw-divide-white/20 tw-space-y-5px [&_>*+*]:tw-py-5px">
			{ subNavData.map((item, index) => {
				return <li key={`subNavData_${ index }`}>
					<SubNavCTA title={ item.title } progress={ item.progress } fields={ fields }/>
				</li>
			}) }
		</ul>
	</>
}

const CustomizePrefixButton = props => {
	// genPrefix={ genPrefix } setGenPrefix={ setGenPrefix } genSerialNumberOuput={ genSerialNumberOuput } setGenSerialNumberOuput={ setGenSerialNumberOuput }
	const container = useRef();
	const [toggleToCustom, setToggleToCustom] = useState(false);
	const [value, setValue] = useState('');

	const handleTogglereFixChange = (e) => {
		e.preventDefault();
		// props.setGenPrefix("");
		setTimeout(() => {
			const field = document.querySelector(`#customizedPrefix${ props.type || '' }`);
			if (field) field.focus();
			props.setCustomPrefix(true)
		}, 150);
		setToggleToCustom(!toggleToCustom);
	}

	const handleCustomPrefix = e => {
		let value = e.target.value || "";

		if (value.length > 1) {
			value = value.replaceAll(" ","").toUpperCase()
		} else {
			value = value.toUpperCase()
		}
		setValue(value);
	}

	const handleAddPrefix = e => {
		e.preventDefault();
		console.log("clicked!", value)
		if (value.length > 4) {
			e.preventDefault();
			props.setGenPrefix(value);
			setTimeout(() => {
				document.querySelector("#gen_sn").focus();
			}, 300);
		} else {
			alert("Please enter a prefix value");
		}
	}

	// useEffect(() => {
	// 	const field = document.querySelector(`#customizedPrefix${ props.type || ''}`);
	// 	if (props?.resetCustom) {
	// 		props.setGenPrefix("");
	// 		if (field) field.value = "";
	// 	}
	// }, [props?.resetCustom, props.value]);

	useEffect(() => {
		props.setGenPrefix(value);
	}, [props, value]);


	useEffect(() => {
		console.log("props.customPrefix", props.customPrefix)
		if (props.customPrefix !== true) {
			setToggleToCustom(false);
			setValue("");
		} else {
			props.setGenPrefix("");
		}
	}, [props, props.customPrefix]);

	useEffect(() => {
		if (container.current) {
			props.setGenPrefix("")
		}
	}, [container, props]);



	return <>
		{ toggleToCustom ? <>
			<div className="tw-flex tw-w-full" ref={ container }>
				<Input type="text" onInput={ handleCustomPrefix } id={`customizedPrefix${ props.type || '' }`} className="tw-min-w-50px tw-flex-grow tw-min-h-[2.625rem] !tw-border-white focus:!tw-font-code focus:!tw-font-code-bold tw-font-code tw-font-code-normal"  minLength={ 5 } maxLength={ 15 } value={ value || "" } />
				<Button onClick={ handleAddPrefix } className="tw-bg-white -tw-ml-1px tw-text-black hover:tw-bg-primary tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-min-h-[2.625rem] tw-border-1px !tw-border-l-black tw-border-white hover:tw-text-black hover:tw-border-white tw-flex-shrink-0 tw-h-full tw-px-10px">
					Add
				</Button>
			</div>
		</> : <>
			<Button onClick={ e => handleTogglereFixChange(e) } className={`tw-bg-black tw-text-white tw-border-1px  tw-min-h-40px ${'tw-border-white' }`}>
				Custom
			</Button>
		</> }
	</>
}

export const toCamelCase = str => str.replaceAll(" ", '');

export const SubNavCTA = props => {
	const [data, setData] = useState(null);
	const getSection = (sectionName) => {
		return props.fields?.find((section) => section.Section === sectionName) || null;
	}

	const handleClick = e => {
		// e.preventDefault();
		// const target = e.target;
		// const id = target.getAttribute("href").replace("#", "");
		// const targetEl = document.querySelector(`#${ id }`);

		// if (targetEl) {
		// 	targetEl.scrollIntoView({
		// 		behavior: "smooth",
		// 		block: "start",
		// 		inline: "nearest"
		// 	})
		// }
	}

	useEffect(() => {
		setData(getSection(props.title));
	}, [props.fields]);

	return <>
		<a href={`#${ toCamelCase(props.title).replaceAll(" ","_") }`} onClick={ handleClick } className="tw-text-inherit tw-transition-opacity tw-duration-500 tw-group">
			<span className="tw-flex-grow tw-opacity-70 group-hover:tw-opacity-100 group-hover:tw-duration-500 tw-duration-150">
				{ props.title }
			</span>
			<span className="tw-flex tw-justify-end tw-items-center tw-flex-shrink-0 tw-space-x-2px">
				<span className="tw=flex-shrink-0 tw-flex tw-items-center tw-text-[0.1em]">
					Status:
				</span>
				<span className="tw-bg-white/20 tw-border-1px tw-border-white/10 tw-flex-grow tw-flex tw-w-30px tw-h-5px">
					<span className={`tw-flex tw-w-full tw-max-w-[var(--max-w)] ${ data && ( data.progress> .9 ? 'tw-bg-lime-500' : (props.progress > .7 ? 'tw-bg-yellow-300' :  'tw-bg-red-300')) }`} style={{ '--max-w': ( props.progress > 0 ? `${props.progress * 100}%` : '0%') }}>
					</span>
				</span>
			</span>
		</a>
	</>
}

//creates a new obj similar to:
// {
// 	title: "Router",
// 	progress: 1
// }, {
// 	title: "Camera",
// 	progress: 0.75
// },
const subNavData = fieldDataFSUnits.map(section => ({
	title: section.Section,
	progress: parseFloat(section.Progress) || 0
}));

export const makeValues = (obj) => {
	const temp = {};
	const promises = fieldDataFSUnits.map((section, index) => {
		return section.items.map((item, itemIndex) => {
			temp[item.name] = obj.get(item.name);
		});
	});

	temp.UUID = obj.get('UUID');
	return Promise.all(promises).then(() => temp);
}

export default function FSUnit() {
	const { id } = useParams();
	const appContext = useAppContext();
	const [fields, setFields] = useState(fieldDataFSUnits);
	const { currentUser } = useAuth();
	const [uuid, setUuid] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_FS_UNITS });
	const [rows, setRows] = useState([]);
	const formRef = useRef();
	// const { products } = useAppContext();
	const [model, setModel] = useState("Alpha");
	const [includesSpeakers, setIncludesSpeakers] = useState("Yes");
	const [numberOfCameras, setNumberOfCameras] = useState("Three (3)");
	const [genPrefix, setGenPrefix] = useState("");
	const [customPrefix, setCustomPrefix] = useState(false);
	const [genSerialNumberOuput, setGenSerialNumberOuput] = useState(null);
	const [cameraCount, setCameraCount] = useState(3);
	const [speakerAvailability, setSpeakerAvailability] = useState(true);
	const [values, setValues] = useState([]);

	useEffect(() => {
		console.log("editMode", editMode);
	}, [editMode]);

	useEffect(() => {
		console.log("values", values);
		// console.log(loadSheet());
	}, [values]);

	const handleUpdate = (event, capitalize) => {
		const field = event && event.target;

		const value = capitalize ? field.value.toUpperCase() : field.value;

		setValues({
			...values,
			[field?.dataset.column]: value
		});
	}

	const addRowToSheet = (obj, message = "Added row to sheet") => {
		return new Promise((resolve, reject) => {
			addRow(obj);
			resolve(message);
		});
	}

	const updateRowInSheet = (obj, message = "Updated row in sheet") => {
		return new Promise((resolve, reject) => {
			loadSheet(true).then((sheets) => {
				// let row = sheets?.find(row => row._rowNumber === obj.index);
				let row = sheetRows?.find( row => row.get('UUID') === obj?.UUID  );

				if (row) {
					const promises = Object.keys(obj).map(key => setRow(row, key, obj[key]));
					Promise.all(promises).then(() => {
						saveRow(row);
						resolve(message);
					});
				} else {
					alert("Could not locate the row")
				}

			});

		});
	}

	const saveSuccess = (target = null, url = null) => {
		if (target) {
			setTimeout(() => {
				target?.classList.remove("_loading")
				target?.classList.add("_success");
				setTimeout(() => {
					target?.classList.remove("_success");
					setEditMode(true);

					if (url) {
						window.scrollTo(0, 0);
						window.location.href = url;
					}
				}, 1000);
			}, 750);
		}
	}

	useLayoutEffect(() => {
		if (uuid && !values?.UUID) {
			setValues({
				...values,
				"UUID": uuid
			});
		}
	}, [uuid, values]);

	const handleSave = (e, update = editMode) => {
		if (e) e.preventDefault();

		const target = e?.target;
		if (target) target.classList.add("_loading");

		// Add disabled fields to values
		const updatedValues = { ...values }; // Start with the current values state
		fieldDataFSUnits.forEach((section) => {
			section.items.forEach((item) => {

				const valueIsBlank = updatedValues[item.name] === '' || updatedValues[item.name] === null;
				if (valueIsBlank && item.defaultValue) {
					// If the value is blank and a defaultValue exists, set it
					updatedValues[item.name] = item.defaultValue;
				}

				// Check if the item is disabled
				const isDisabled = Array.isArray(item.disabled) && item.disabled.some(disabledItem => disabledItem.disabled === "true");
				if (isDisabled && !updatedValues[item.name]) {
					// Add the default value for the disabled field
					const defaultValue = item.disabled.find(disabledItem => disabledItem.defaultValue)?.defaultValue || '';
					updatedValues[item.name] = defaultValue;
				}
			});
		});

		// look for updates values, check each one if value is a blank string, set the value to the default value


		// Construct the tempEntries object with updatedValues
		const tempEntries = update
			? {
				  ...updatedValues, // Use updatedValues here
				  "Updated On": new Date().toISOString(),
				  "Updated By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			  }
			: {
				  ...updatedValues, // Use updatedValues here
				  "Created On": new Date().toISOString(),
				  "Updated On": new Date().toISOString(),
				  "Created By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
				  "Updated By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			  };

		const entries = removeUnderscore(tempEntries);

		if (update) {
			// Update row
			updateRowInSheet(entries, `Updated row ${JSON.stringify(entries, null, 2)} in sheet`).then((message) => {
				console.log(message);
				saveSuccess(target);
			});
		} else {
			// Add new row
			addRowToSheet(entries, `Added row ${JSON.stringify(entries, null, 2)} in sheet`).then((message) => {
				console.log(message);
				saveSuccess(target);
			});
		}

		// return new Promise((resolve, reject) => {
		// 	const productName = getPropertyFromId(products, Number(product), 'title');
		// 	addRow({
		// 		'ID': product,
		// 		'Product Name': `${ productName }`,
		// 		'Modified Date': `${ new Date().toISOString() }`,
		// 		'Modified By': `${ currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown' }`
		// 	});
		// 	resolve(`"${productName}" (ID: ${ product })`);
		// });
	}

	const handleIncludesSpeakerChange = (e, value) => {
		e.preventDefault();

		if (!value) return;
		setIncludesSpeakers(value)

		setValues({
			...values,
			"Includes Speaker": value || null
		})

	}

	const handleNumberOfCamerasChange = (e, value) => {
		e.preventDefault();

		if (!value) return;
		setNumberOfCameras(value)
		// props.setValues({
		// 	...props.values,
		// 	"Model": value || null
		// });

		setValues({
			...values,
			"Number of Cameras": value || null
		})

		// if (value === '2800i') {
		// 	setLastDigitsInputLength(6);
		// } else if (value === '5500i') {
		// 	setLastDigitsInputLength(6);
		// } else {
		// 	setLastDigitsInputLength(6);
		// }

		// setModel(value);
		// setSerialPrefix(null);
		// setLastDigitsInputValue('');
	}

	const handleModelChange = (e, value) => {
		e.preventDefault();

		console.log('value: ', value);

		if (!value) return;
		setModel(value)
		console.log(values)

		setValues({
			...values,
			"Model": value || null
		})
	}

	const handleCameraCountChange = (e, value) => {
		e.preventDefault();

		if (!value) return;
		setCameraCount(Number(value))
		// props.setValues({
		// 	...props.values,
		// 	"Model": value || null
		// });

		// if (value === '2800i') {
		// 	setLastDigitsInputLength(6);
		// } else if (value === '5500i') {
		// 	setLastDigitsInputLength(6);
		// } else {
		// 	setLastDigitsInputLength(6);
		// }

		// setModel(value);
		// setSerialPrefix(null);
		// setLastDigitsInputValue('');
	}


	const handleSpeakerAvailabilityChange = (e, value) => {
		e.preventDefault();

		if (!value) return;
		setSpeakerAvailability( value === 1 ? true : false )
		// props.setValues({
		// 	...props.values,
		// 	"Model": value || null
		// });

		// if (value === '2800i') {
		// 	setLastDigitsInputLength(6);
		// } else if (value === '5500i') {
		// 	setLastDigitsInputLength(6);
		// } else {
		// 	setLastDigitsInputLength(6);
		// }

		// setModel(value);
		// setSerialPrefix(null);
		// setLastDigitsInputValue('');
	}

	const handleGenSn = e => {
		const value = e ? document.querySelector("#gen_sn")?.value : e?.target?.value || "";

		const newGenSerialNumberOuput = value ? genPrefix + value.toString().replaceAll(" ", "").toUpperCase() : '';

		setGenSerialNumberOuput(newGenSerialNumberOuput);

		//handle update of value in form
		console.log("before", values);
		setValues(prevValues => ({
			...values,
			"Generator Serial Number": newGenSerialNumberOuput || null
		}));
		console.log("after", values);
	}

	useEffect(() => {
		handleGenSn();
	}, [genPrefix]);

	const handlePrefixChange = (e, value = null) => {
		if (e) e.preventDefault();
		const temp = value ? value : e?.target?.value || "";
		setCustomPrefix(false);
		setGenPrefix(temp);
	}

	const handleSubmit = e => {
		if (e) e.preventDefault();

		alert("Submitted")
	}

	useEffect(() => {
		console.log("genSerialNumberOuput", genSerialNumberOuput);
	}, [genSerialNumberOuput])


	useEffect(() => {
		if (rows?.length) {
			appContext.setFsUnits(rows);
		}
	}, [rows]);

	//Loads the sheet and sets the values, otherwise creates new row (sets UUID)
	useLayoutEffect(() => {
		loadSheet(true).then((sheets) => {
			if (id) {
				let row = sheets?.find( row => row.get('UUID') === id.toString() );
				makeValues(row).then((values) => {
					console.log('makeValues', values);
					setValues(values);
					setUuid(values.UUID);
					setEditMode(true);
				});
			} else {
				setUuid(generateUUID());
				setRows(sheets);
			}
		});
	}, []);


	useLayoutEffect(() => {
		initProgress(fields);
	}, []);

	useEffect(() => {
		console.log("fields", fields);
	}, [fields]);


	return <>
		<div className="tw-w-full tw-px-40d tw-flex tw-space-x-40d">
			<div className="">
				<QCSubNav subNavData={ subNavData } fields={ fields } />
			</div>
			<form ref={ formRef } className="tw-w-full tw-space-y-40px tw-pb-60px">
				{/* { appContext.rows && <div>
					Demo!
					<br />
					{ rows.map((row, index) => {
						const demoUUID = row.get('UUID');
						return demoUUID && <>
						 { row.get('UUID') } -
						 { row.get('Unit Name') } - <br />
						 { row.get('Model') } <br />
						</>
					}) }
				</div> } */}
				{fieldDataFSUnits.map((section, index) => (
					<FieldsetWrapperFS values={ values } setFields={ setFields } fields={ fields } key={index} title={section.Section} className="c-unit-field" id={toCamelCase(section.Section).replaceAll(" ","_")}>
						{/* <div>
							{ section.Description }
						</div> */}
						{ section.Section === "Generator" ? <>
							{ model === "Alpha" ? <>
								<Field type="text" className="tw-flex-grow " value={values['Generator Model'] || ''}  required id={`gen_model`} data-column="Generator Model" label='<span>Generator Model</span>' placeholder="Generator Model" onChange={ handleUpdate } />
								<Field type="text" className="tw-flex-grow " value={values['Generator Serial Number'] || ''}  required id={`gen_SN`} data-column="Generator Serial Number" label='<span>Generator Serial Number</span>' placeholder="Generator Serial Number" onChange={ handleUpdate } />

								{/* <Field type="text" disabled className="tw-flex-grow " required initValue={`2800i`} value={`2800i`} id={`gen_model`} data-column="Generator Model" label='<span>Generator Model</span>' placeholder="Generator Model" onChange={ handleUpdate } />
								<Label default className="tw-min-h-30px tw-flex tw-items-end tw-py-2px tw-pointer-events-none">
									<span>
										Select Prefix:
									</span>
								</Label>
								<div className="tw-w-full tw-grid tw-grid-cols-2 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex [&_*]:tw-font-code [&_*]:tw-font-code-bold">
									<Button onClick={ e => handlePrefixChange(e, '4L1R0AB2R')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ customPrefix === false && genPrefix?.includes('4L1R0AB2R') ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
										<span className="tw-font-code tw-font-code-normal">4L1R0AB2R</span>
									</Button>
									<CustomizePrefixButton genPrefix={ genPrefix } customPrefix={ customPrefix === true ? true : false } setCustomPrefix={ setCustomPrefix } setGenPrefix={ setGenPrefix } genSerialNumberOuput={ genSerialNumberOuput } setGenSerialNumberOuput={ setGenSerialNumberOuput } type="gen_custom"/>
								</div>
								<Field type="tel" disabled={ genPrefix?.length > 1 ? null : 'disabled' } className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`gen_sn`} data-column="Generator SN" label='<span>Serial Number</span>' placeholder="Enter Last Six" minLength={ 6 } maxLength={ 6 } onChange={ handleGenSn } />
								<span className={`tw-w-full tw-max-h-0px tw-transition-all tw-duration-300 tw-delay-500 tw-overflow-hidden tw-block ${(genSerialNumberOuput?.length > 14) ? 'tw-opacity-100' : 'tw-opacity-0' }`} style={{ "maxHeight": (genSerialNumberOuput?.length > 14) ? '50px' : '0px' }} >
									<span className="tw-flex tw-w-full tw-items-center tw-justify-center">
										<span className="tw-flex tw-w-full tw-items-center tw-justify-center tw-border-1px tw-border-solid tw-border-white tw-p-10px">
											SN:&nbsp;<span className="tw-font-code tw-font-code-normal tw-inline-block tw-whitespace-nowrap">{ genSerialNumberOuput }</span>
										</span>
									</span>
								</span> */}
							</> : <></> }
						</> : <>
							{ section.items.map((item, itemIndex) => {
								// Check if the item contains a disabled key with an array that includes an object where disabled is "true"
								const isDisabled = Array.isArray(item.disabled) && item.disabled.some(disabledItem => disabledItem.disabled === "true");
								// If the item is disabled, find the defaultValue from the disabled array
								const defaultValue = isDisabled ? item.disabled.find(disabledItem => disabledItem.defaultValue)?.defaultValue : '';

								return (
									<>
										{ !item.name ? <>
											<hr className="!tw-mt-40px !tw-mb-30px tw-border-t-1px tw-border-b-0px tw-border-white/50"/>
										</> : (
										item.type === "number-of-cameras" ? (
											<NumberOfCameras numberOfCameras={numberOfCameras} title={item.name} selectList={item.selectList} setValues={setValues} handleNumberOfCamerasChange={handleNumberOfCamerasChange} />
										) : item.type === "includes-speakers" ? (
											<IncludesSpeakers includesSpeakers={includesSpeakers} title={item.name} selectList={item.selectList} setValues={setValues} handleIncludesSpeakerChange={handleIncludesSpeakerChange} />
										) : item.type === 'select-model' ? (
											<DynamicSelectorButtons setValues={ setValues } values={ values } title={ item.name } obj={[{
												"key": "Model",
												"value": "Alpha",
												"src" : "/images/selection-alpha.svg"
											}, {
												"key": "Model",
												"value": "Bravo",
												"src" : "/images/selection-bravo.svg"
											}, {
												"key": "Model",
												"value": "Pogo",
												"src" : "/images/selection-pogo.svg"
											}]} />
											// <SelectModel handleModelChange={handleModelChange} values={ values } title={item.name} />
										) : section.Section === 'Generator' ? <></>
										:(
											<Field
												key={itemIndex}
												fieldData={item}
												type={item.type || 'text'}
												className="tw-flex-grow"
												required={item.required === true}
												initValue={ values[item.name] || '' }
												value={isDisabled ? defaultValue : values[item.name] || ''}
												id={`${item.name.replaceAll(" ","_").toLowerCase()}`}
												data-column={item.name}
												label={`<span>${item.name}</span>`}
												placeholder={item.description}
												onChange={ e => handleUpdate(e, item.capitalize || false)}
												disabled={isDisabled}
												model={model}
												textInputValue={item.textInputValue}
												defaultValue={item.defaultValue}
											/>
										))}
									</>
								);
							})}
						</> }
						<button onClick={handleSave}>
							<span className="tw-pointer-events-none">Submit</span>
						</button>
					</FieldsetWrapperFS>
				))}



				{/* <div class="tw-border tw-border-pink-400">
					Old fields

					<FieldsetWrapperFS title="Test">
						<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`test_field`} data-column="Test Field" label='<span>Test Field</span>' placeholder="Test Field" onChange={ handleUpdate } />
						<button onClick={ handleSave }>
							<span className="tw-pointer-events-none">Submit</span>
						</button>
					</FieldsetWrapperFS>

					<FieldsetWrapperFS title="Overview">
						<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`unit_name`} data-column="Unit Name" label='<span>Unit Name</span>' placeholder="Unit Name" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`vin_number`} data-column="VIN" label='<span>VIN Number</span>' placeholder="VIN Number" onChange={ handleUpdate } />
						<div>
							<Label default className="tw-flex tw-items-end tw-pb-2px tw-pointer-events-none">
								<span>
									Select Model:
								</span>
							</Label>
							<div className="tw-w-full tw-grid tw-grid-cols-3 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex">
								<Button onClick={ e => handleModelChange(e, 'Alpha')} className={`tw-rounded-l-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ model === 'Alpha' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									<AspectImage src={`/images/selection-alpha.svg`} aspect="411/74" className="tw-w-full tw-max-h-20px" />
								</Button>
								<Button onClick={ e => handleModelChange(e, 'Bravo')} className={`tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ model === 'Bravo' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									<AspectImage src={`/images/selection-bravo.svg`} aspect="411/74" className="tw-w-full tw-max-h-20px" />
								</Button>
								<Button onClick={ e => handleModelChange(e, 'Pogo')} className={`tw-rounded-r-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ model === 'Pogo' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									<AspectImage src={`/images/selection-pogo.svg`} aspect="411/74" className="tw-w-full tw-max-h-20px" />
								</Button>
							</div>
						</div>
						<div>
							<Label default className="tw-flex tw-items-end tw-pb-2px tw-pointer-events-none">
								<span>
									Number of Cameras
								</span>
							</Label>
							<div className="tw-w-full tw-grid tw-grid-cols-3 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex">
								<Button onClick={ e => handleCameraCountChange(e, 1)} className={`tw-rounded-l-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ cameraCount === 1 ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									One (1)
								</Button>
								<Button onClick={ e => handleCameraCountChange(e, 2)} className={`tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ cameraCount === 2 ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									Two (2)
								</Button>
								<Button onClick={ e => handleCameraCountChange(e, 3)} className={`tw-rounded-r-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ cameraCount === 3 ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									Three (3)
								</Button>
							</div>
						</div>
						<div>
							<Label default className="tw-flex tw-items-end tw-pb-2px tw-pointer-events-none">
								<span>
									Includes speaker?
								</span>
							</Label>
							<div className="tw-w-full tw-grid tw-grid-cols-3 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex">
								<Button onClick={ e => handleSpeakerAvailabilityChange(e, 1)} className={`tw-rounded-l-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ speakerAvailability === true ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									Yes
								</Button>
								<Button onClick={ e => handleSpeakerAvailabilityChange(e, 2)} className={`tw-rounded-r-3px tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ !speakerAvailability ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
									No
								</Button>
							</div>
						</div>
					</FieldsetWrapperFS>
					<FieldsetWrapperFS title="Router">
						<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`Teltonika`} value={`Teltonika`} id={`router_make`} data-column="Router Make" label='<span>Router Make</span>' placeholder="Router Make" onChange={ handleUpdate } />
						<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`RUTM50`} value={`RUTM50`} id={`router_model`} data-column="Router Model" label='<span>Router Model</span>' placeholder="Router Model" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`router_sn`} data-column="Router SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`router_mac`} data-column="Router MAC Address" label='<span>MAC Address</span>' placeholder="Router MAC Address" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`router_lan_mac`} data-column="Router LAN MAC" label='<span>LAN MAC</span>' placeholder="LAN MAC" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`router_lan_mac`} data-column="Router IMEI" label='<span>IMEI</span>' placeholder="IMEI" onChange={ handleUpdate } />
						<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`router_un`} data-column="Username" label='<span>Username</span>' placeholder="Username" onChange={ handleUpdate } />
						<Field type="passwordToggle" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`router_pw`} data-column="Password" label='<span>Password</span>' placeholder="Password" onChange={ handleUpdate } />
					</FieldsetWrapperFS>
					<FieldsetWrapperFS title="Camera 1">
						<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`camera_1_name`} data-column="Camera 1 Name" label='<span>Name</span>' placeholder="Unit Name + Camera #" onChange={ handleUpdate } />
						<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`Sunell`} value={`Sunell`} id={`camera_1_make`} data-column="Camera 1 Make" label='<span>Camera Make</span>' placeholder="Camera Make" onChange={ handleUpdate } />
						<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`SN-IPS8620TDR-Z25-WO`} value={`SN-IPS8620TDR-Z25-WO`} id={`camera_1_model`} data-column="Camera 1 Model" label='<span>Camera Model</span>' placeholder="Camera Model" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_1_sn`} data-column="Camera 2 SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
						<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_1_mac`} data-column="Camera 2 MAC Address" label='<span>MAC Address</span>' placeholder="Camera MAC Address" onChange={ handleUpdate } />
						<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`192.168.1.101`} value={`192.168.1.101`} id={`camera_1_ip`} data-column="Camera 2 IP" label='<span>IP Address</span>' placeholder="IP Address" onChange={ handleUpdate } />
						<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`camera_1_un`} data-column="Username" label='<span>Username</span>' placeholder="Username" onChange={ handleUpdate } />
						<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`camera_1_pw`} data-column="Password" label='<span>Password</span>' placeholder="Password" onChange={ handleUpdate } />
					</FieldsetWrapperFS>
					{ cameraCount > 1 ? <>
						<FieldsetWrapperFS title="Camera 2">
							<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`camera_2_name`} data-column="Camera 2 Name" label='<span>Name</span>' placeholder="Unit Name + Camera #" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`Sunell`} value={`Sunell`} id={`camera_2_make`} data-column="Camera 2 Make" label='<span>Camera Make</span>' placeholder="Camera Make" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`SN-IPS8620TDR-Z25-WO`} value={`SN-IPS8620TDR-Z25-WO`} id={`camera_2_model`} data-column="Camera 2 Model" label='<span>Camera Model</span>' placeholder="Camera Model" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_2_sn`} data-column="Camera 2 SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_2_mac`} data-column="Camera 2 MAC Address" label='<span>MAC Address</span>' placeholder="Camera MAC Address" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`192.168.1.102`} value={`192.168.1.101`} id={`camera_2_ip`} data-column="Camera 2 IP" label='<span>IP Address</span>' placeholder="IP Address" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`camera_2_un`} data-column="Username" label='<span>Username</span>' placeholder="Username" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_2_pw`} data-column="Password" label='<span>Password</span>' placeholder="Password" onChange={ handleUpdate } />
						</FieldsetWrapperFS>
					</>: <></>}
					{ cameraCount > 2 ? <>
						<FieldsetWrapperFS title="Camera 3">
							<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`camera_3_name`} data-column="Camera 3 Name" label='<span>Name</span>' placeholder="Unit Name + Camera #" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`Sunell`} value={`Sunell`} id={`camera_3_make`} data-column="Camera 3 Make" label='<span>Camera Make</span>' placeholder="Camera Make" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`SN-IPS8620TDR-Z25-WO`} value={`SN-IPS8620TDR-Z25-WO`} id={`camera_3_model`} data-column="Camera 3 Model" label='<span>Camera Model</span>' placeholder="Camera Model" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_3_sn`} data-column="Camera 3 SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_3_mac`} data-column="Camera 3 MAC Address" label='<span>MAC Address</span>' placeholder="Camera MAC Address" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white focus:!tw-font-code focus:!tw-font-code-normal" required initValue={`192.168.1.101`} value={`192.168.1.101`} id={`camera_3_ip`} data-column="Camera 3 IP" label='<span>IP Address</span>' placeholder="IP Address" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`camera_3_un`} data-column="Username" label='<span>Username</span>' placeholder="Username" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`camera_3_pw`} data-column="Password" label='<span>Password</span>' placeholder="Password" onChange={ handleUpdate } />
						</FieldsetWrapperFS>
					</>: <></>}
					{ speakerAvailability ? <>
						<FieldsetWrapperFS title="Speaker">
							<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`speaker_name`} data-column="Speaker 1 Name" label='<span>Name</span>' placeholder="Unit Name + Speaker" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`Sunell`} value={`Sunell`} id={`speaker_make`} data-column="Speaker Make" label='<span>Speaker Make</span>' placeholder="Speaker Make" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`SN-PA1H01-M-30`} value={`SN-PA1H01-M-30`} id={`speaker_model`} data-column="Speaker Model" label='<span>Speaker Model</span>' placeholder="Speaker Model" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`speaker_sn`} data-column="Speaker SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`speaker_mac`} data-column="Speaker MAC Address" label='<span>MAC Address</span>' placeholder="Speaker MAC Address" onChange={ handleUpdate } />
							<Field type="text" disabled className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`192.168.1.22`} value={`192.168.1.22`} id={`speaker_ip`} data-column="Speaker IP" label='<span>IP Address</span>' placeholder="IP Address" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow" required initValue={``} value={``} id={`speaker_un`} data-column="Username" label='<span>Username</span>' placeholder="Username" onChange={ handleUpdate } />
							<Field type="passwordToggle" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`speaker_pw`} data-column="Password" label='<span>Password</span>' placeholder="Password" onChange={ handleUpdate } />
						</FieldsetWrapperFS>
					</> : <></> }
					{ model !== "Pogo" ? <>
						<FieldsetWrapperFS title="Victron Unit">
							<Field type="text" className="tw-flex-grow [&_input]:!tw-text-white" required initValue={`SN-PA1H01-M-30`} value={`SN-PA1H01-M-30`} id={`victron_model`} data-column="Victron Model" label='<span>Victron Model</span>' placeholder="Victron Model" onChange={ handleUpdate } />
							<Field type="text" className="tw-flex-grow focus:!tw-font-code focus:!tw-font-code-normal" required initValue={``} value={``} id={`victron_sn`} data-column="Victron SN" label='<span>Serial Number</span>' placeholder="Serial Number" onChange={ handleUpdate } />
						</FieldsetWrapperFS>
					</> : <></> }
				</div> */}




				<div className="tw-w-full tw-flex tw-justify-end">
					<ButtonLight type="submit" className="tw-uppercase" default>
						<span className="tw-text-[1.5em] tw-py-5px tw-px-20px tw-w-full tw-flex tw-items-center tw-justify-center">
							Add Unit
						</span>
					</ButtonLight>
				</div>
			</form>
		</div>
	</>
}
