import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Inventory from "../../dashboard/inventory/page";
import { useAppContext } from "../../../contexts/app";
import { Field, Input, Label, IncludesSpeakers, NumberOfCameras, DynamicSelectorButtons } from "../../../components/forms";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { Button } from "../../../components/ctas";
import QCUnitStatus from "../../../components/qc-unit-status";
import { FieldsetWrapperFS } from "../unit/page";

const subNavData = [{
	title: "Router",
	progress: 1
}, {
	title: "Camera",
	progress: 0.75
}, {
	title: "Speaker",
	progress: 0.4
}, {
	title: "Victron",
	progress: 0.00
}, {
	title: "Generator",
	progress: 0.00
}]

console.log('subNavData', subNavData)

const TableauEmbed = () => {
	const vizRef = useRef(null);

	useEffect(() => {
	  const vizElement = vizRef.current;
	  if (vizElement) {
		vizElement.style.width = "100%";
		vizElement.style.height = `${vizElement.parentElement.offsetWidth * 0.75}px`;

		const scriptElement = document.createElement("script");
		scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
		scriptElement.async = true;
		vizElement.parentNode.insertBefore(scriptElement, vizElement);
	  }
	}, []);

	return (
	  <div
		className="tableauPlaceholder"
		id="viz1743186799076"
		style={{ position: "relative" }}
	  >
		<noscript>
		  <a href="#">
			<img
			  alt="FS Unit Progress"
			  src="https://public.tableau.com/static/images/Bo/Book1_17431867791100/Sheet1/1_rss.png"
			  style={{ border: "none" }}
			/>
		  </a>
		</noscript>
		<object
		  ref={vizRef}
		  className="tableauViz"
		  style={{ display: "none" }}
		>
		  <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
		  <param name="embed_code_version" value="3" />
		  <param name="site_root" value="" />
		  <param name="name" value="Book1_17431867791100/Sheet1" />
		  <param name="tabs" value="no" />
		  <param name="toolbar" value="yes" />
		  <param
			name="static_image"
			value="https://public.tableau.com/static/images/Bo/Book1_17431867791100/Sheet1/1.png"
		  />
		  <param name="animate_transition" value="yes" />
		  <param name="display_static_image" value="yes" />
		  <param name="display_spinner" value="yes" />
		  <param name="display_overlay" value="yes" />
		  <param name="display_count" value="yes" />
		  <param name="language" value="en-US" />
		  <param name="filter" value="publish=yes" />
		</object>
	  </div>
	);
  };

const InventoryItem = ({ uuid, unitName, qcStatus }) => {
	return <>
		<div className="tw-border tw-border-pink-200 tw-my-10 tw-p-5">
			<h1>{ unitName }</h1>
			{/* <QCUnitStatus subNavData={ subNavData } /> */}
			<a href={`/fs/unit/${uuid}`} className="tw-text-white tw-text-lg tw-font-bold tw-underline">
				<span className="tw-pointer-events-none">
					Edit
				</span>
			</a>
		</div>
	</>
}

export default function FSInventory() {

	const appContext = useAppContext();
	// const [editMode, setEditMode] = useState(false);
	const [rows, setRows] = useState([]);
	const [qcRows, setQcRows] = useState([]);
	const [progressRows, setProgressRows] = useState([]);
	const [unitSearch, setUnitSearch] = useState("");

	//Units google sheet
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_FS_UNITS });
	//Units QC PDI google sheet
	const { loadSheet: loadQCSheet, } = useGoogleSheet({spreadsheetId: process.env.REACT_APP_FS_PDI });
	//Units QC Progress google sheet
	const { loadSheet: loadProgressSheet } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_FS_PDI });

	useLayoutEffect(() => {
		loadSheet(true).then((sheets) => {
			setRows(sheets);
		});
		loadQCSheet(true).then((sheets) => {
			setQcRows(sheets);
		});
		loadProgressSheet(true, 1).then((sheets) => {
			setProgressRows(sheets);
		});
	}, []);

	// Filter rows based on the search input
	const filteredRows = rows.filter((row) => {
		const unitName = row.get("Unit Name");
		return unitName && unitName.toLowerCase().includes(unitSearch.toLowerCase());
	});

	console.log('qcRows', qcRows)
	console.log('progressRows', progressRows)

	// const headerValues = qcRows[0] && qcRows[0]['_worksheet']['_headerValues'];
	// console.log('headerValues Object', JSON.stringify(headerValues, null, 2))


	// useEffect(() => {
	// 	if (!qcRows.length || !headerValues) return;

	// 	const qcRowsWithHeader = qcRows.map((row) => {
	// 		const newRow = {};
	// 		headerValues.forEach((header) => {
	// 			newRow[header] = row[header] || null; // Add corresponding headerValue to the new object
	// 		});
	// 		return newRow;
	// 	});

	// 	console.log('Mapped QC Rows:', qcRowsWithHeader.map((row) => JSON.stringify(row, null, 2)));
	// }, [headerValues, qcRows]);


	// const qcProgressObj = [{
	// 	title: "Camera Housing",
	// 	progress: 0.928
	// },
	// {
	// 	title: "Connection ",
	// 	progress: 0.928
	// }]



	return <>
		<div className="tw-w-full">
			{ appContext.rows &&
				<div>
					<h1>Units</h1>

				<TableauEmbed />
				<br />

				 {/* Search Input Field */}
				 <FieldsetWrapperFS title="Search Units">
					<Field
					type="text"
					value={unitSearch}
					onChange={(e) => setUnitSearch(e.target.value)} // Update search state
					/>
					<Label>Search</Label>
				</FieldsetWrapperFS>

					{/* <div class="tableauPlaceholder" id="viz1743186799076" style="position: relative">
							<noscript>
								<a href="#">
									<img alt="FS Unit Progress " src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;Bo&#47;Book1_17431867791100&#47;Sheet1&#47;1_rss.png" style="border: none" />
								</a>
							</noscript>
							<object class="tableauViz" style="display:none;">
								<param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" /> <param name="embed_code_version" value="3" /> <param name="site_root" value="" />
								<param name="name" value="Book1_17431867791100&#47;Sheet1" />
								<param name="tabs" value="no" />
								<param name="toolbar" value="yes" />
								<param name="static_image" value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;Bo&#47;Book1_17431867791100&#47;Sheet1&#47;1.png" /> <param name="animate_transition" value="yes" />
								<param name="display_static_image" value="yes" />
								<param name="display_spinner" value="yes" />
								<param name="display_overlay" value="yes" />
								<param name="display_count" value="yes" />
								<param name="language" value="en-US" />
								<param name="filter" value="publish=yes" />
							</object>
						</div>{" "}
						<script type="text/javascript"> var divElement = document.getElementById('viz1743186799076'); var vizElement = divElement.getElementsByTagName('object')[0]; vizElement.style.width='100%';vizElement.style.height=(divElement.offsetWidth*0.75)+'px'; var scriptElement = document.createElement('script'); scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js'; vizElement.parentNode.insertBefore(scriptElement, vizElement); </script> */}



					<br />
					{/* { rows.map((row, index) => {
							const unitName = row.get('Unit Name');
							const qcRow = qcRows.find((qcRow) => qcRow.get('Unit Name') === unitName);
							if (qcRow) {
							const housingProgress = qcRow.get('Housing Progress');
							// console.log('housing progress', { unitName: unitName, progress: housingProgress})
							}
							const uuid = row.get('UUID');
							return uuid && <InventoryItem uuid={ uuid } unitName={ row.get('Unit Name')} />
						})
					} */}

					{/* Render Filtered Inventory Items */}
					{filteredRows.map((row) => {
						const unitName = row.get("Unit Name");
						const uuid = row.get("UUID");
						return (
						uuid && (
							<InventoryItem
							key={uuid}
							uuid={uuid}
							unitName={unitName}
							/>
						)
						);
					})}

				</div>
			}
		</div>
	</>
}
