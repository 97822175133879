import { Field, Input, Label } from "../../../components/forms";
import { ButtonPrimary } from "../../../components/ctas";
import { AspectImage } from "../../../components/assets";
import { ArticleHeader, PageHeader } from "../../../components/headers";
import { useEffect, useLayoutEffect, useState } from "react";
import { getProducts } from "../../../utils/api";
import { useAppContext } from "../../../contexts/app";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { digitsOnly, formatNumber, replaceChar } from "../../../utils/formatting";
import { useAuth } from "../../../hooks/useAuth";
import { getPropertyFromId, stringToArray, timeDiff, wrapText, wrapTextArray } from "../../../utils/global";

export default function WeightDims() {
	const { currentUser } = useAuth();
	const [filteredProducts, setFilteredProducts] = useState(null);
	const [productsAlphabetically, setProductsAlphabetically] = useState(null);
	const { products, setProducts } = useAppContext();

	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_SHIP_DIMS_SPREADSHEET_ID });


	const sortProductsAlphabetically = (products) => {
		let productsArray = [];
		for (let key in products) {
			productsArray.push(products[key]);
		}
		productsArray.sort((a, b) => {
			if (a.title < b.title) {
				return -1;
			} else if (a.title > b.title) {
				return 1;
			} else {
				return 0;
			}
		});
		return productsArray;
	}

	/*
	const filterDataFullMatch = (dataObj, searchTerm) => {
		let search = searchTerm.toLowerCase();
		return dataObj.reduce((acc, curr) => {
			let title = curr.title.toLowerCase();
			let body = curr.body_html.toLowerCase();
			let handle = curr.handle.toLowerCase();
			if (title.includes(search) || body.includes(search) || handle.includes(search)) {
				acc.push(curr);
			}
			return acc;
		}, []);
	}
	*/

	const filterData = (dataObj, searchTerm) => { // partial matches
		let search = searchTerm.toString().toLowerCase();

		return dataObj.reduce((acc, curr) => {
			let title = curr.title.toString().toLowerCase();
			// let body = curr.body_html.toString().toLowerCase();
			// let handle = curr.handle.toString().toLowerCase();
			let sku = curr.variants[0]?.sku?.toString().toLowerCase();

			// if (title.includes(search) || body.includes(search) || handle.includes(search) || sku?.includes(search) || sku === search) {
			if (title.includes(search) || sku?.includes(search)) {
				acc.push(curr);
			}
			return acc;
		}, []);
	}


	useEffect(() => {
		if (filteredProducts) setProductsAlphabetically(sortProductsAlphabetically(filteredProducts))
	}, [filteredProducts]);

	useEffect(() => {
		if (products) setFilteredProducts(products)
	}, [products]);

	const InitProducts = (cache = false) => {
		if (cache) {
			setProducts(JSON.parse(localStorage.getItem('products')));
			loadSheet();
		} else {
			localStorage.setItem('sync_date', new Date().toISOString());
			getProducts().then(res => {
				if (res){
					localStorage.setItem('products', JSON.stringify(res.products))
					setProducts(res.products);
					loadSheet();
				}
			});
		}
	}

	useLayoutEffect(() => {
		const now = new Date().toISOString();
		if (localStorage.getItem('sync_date')) {
			if ( (timeDiff(localStorage.getItem('sync_date'), now, true) < 15) && localStorage.getItem('products') ) {
				InitProducts(true);
			} else {
				InitProducts();
			}
		} else {
			InitProducts();
		}
	}, []);


	const createRow = (product) => {
		return new Promise((resolve, reject) => {
			const productName = getPropertyFromId(products, Number(product), 'title');
			addRow({
				'ID': product,
				'Product Name': `${ productName }`,
				'Modified Date': `${ new Date().toISOString() }`,
				'Modified By': `${ currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown' }`
			});
			resolve(`"${productName}" (ID: ${ product })`);
		});
	}


	const handleUpdate = (event, save = false, target = null) => {
		const field = save ? event : event.target;
		const product = field.parentNode.parentNode.parentNode.dataset?.product || field.parentNode.dataset?.product || null;
		const columnName = field.parentNode.parentNode.parentNode.dataset?.column || field.parentNode.dataset?.column || null;

		if (product && columnName) {
			let row = sheetRows?.find( row => row.get('ID') === product );

			if (!row) {
				createRow(product).then((res) => {
					console.log("New Product Created in Spreadsheet", res)
				});
			}

			const value = field.value ? (field.type === 'tel' && (field.getAttribute("format") && field.getAttribute("format") !== "none" && field.getAttribute("format") !== "replaceChar") ? digitsOnly(field.value) : ((field.getAttribute("format") && field.getAttribute("format") === "replaceChar") ? (digitsOnly(replaceChar(field.value), true, '0', true)) : field.value) ) : '';

			field.value = value ? (field.type === 'tel' && (field.getAttribute("format") && field.getAttribute("format") !== "none" && field.getAttribute("format") !== "replaceChar") ? formatNumber(value) : ( (field.getAttribute("format") && field.getAttribute("format") === "replaceChar") ? (digitsOnly(replaceChar(value), true, '0', true)) : value) ) : '';

			if (save) {
				setRow(row, 'Modified Date', new Date().toISOString());
				setRow(row, 'Modified By', currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown');
				setRow(row, columnName, value);
				saveRow(row);

				if (target) {
					setTimeout(() => {
						target.classList.remove("_loading")
					}, 750);
				}
			}
		}
	}

	const handleSave = (e) => {
		e.preventDefault();
		const target = e.target;
		target.classList.add("_loading")
		document.querySelectorAll(`[data-fieldset="${ e.target.dataset.product }"] input[data-product], [data-fieldset="${ e.target.dataset.product }"] textarea[data-product]`).forEach(field => {
			handleUpdate(field, true, target);
		});
	}

	return <>
		<div className="tw-space-y-30px tw-pb-60px">
			<PageHeader className="tw-text-primary tw-w-full tw-text-center tw-leading-[.9]">
				<span className="tw-font-primary tw-font-primary-light">RVMP</span>{` `}Weight &amp; Dims
			</PageHeader>
			<section>
				<form className="tw-w-full tw-min-h-screen" onSubmit={ e => { e.preventDefault() }}>
					{ productsAlphabetically && <div className="tw-space-y-40px tw-w-full tw-max-w-500px tw-p-20px md:tw-p-30px tw-rounded-12px tw-bg-[#292826] tw-border-1px tw-border-white/10 tw-shadow-sm tw-mx-auto">
						<div className="tw-border-b-1px tw-border-white/20 tw-pb-20px md:tw-pb-30px tw-mb-30px">
							<form onSubmit={ e => e.preventDefault() }>
								<div className="tw-flex tw-space-x-15px">
									<Label htmlFor="searchElement" className="tw-items-center tw-flex-shrink-0 md:tw-min-w-60px" default>
										<span className="tw-flex tw-flex-col tw-w-full">
											Filter
											<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60 tw-hidden md:tw-block">Results: { productsAlphabetically.length }</span>
										</span>
									</Label>
									<div className="tw-flex-grow">
										<Input className="tw-w-full" type="search" id="searchElement" list="product-list"  placeholder="Search for product..." onChange={(e) => setFilteredProducts(filterData(products, e.target.value))} />
									</div>
								</div>
								<div className="tw-block md:tw-hidden tw-text-right">
									<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60">Results: { productsAlphabetically.length }</span>
								</div>
								<button type="submit" className="tw-hidden" onSubmit={ e => e.preventDefault() } onClick={ e => e.preventDefault() }>
									Search
								</button>
							</form>
						</div>
						{ productsAlphabetically.length > 0 ? productsAlphabetically.map( (product, index) => {

							{/* const sheetRow = sheetRows?.[index]; is this safe enough???*/}
							const sheetRow = sheetRows?.find(row => row.get('ID') === product.id.toString());
							const initialProductWeight = sheetRow?.get('Product Weight') || "";
							const initialShippingWeight = sheetRow?.get('Shipping Weight') || "";
							const initialProductDimensionsL = sheetRow?.get('Product Length') || "";
							const initialProductDimensionsW = sheetRow?.get('Product Width') || "";
							const initialProductDimensionsH = sheetRow?.get('Product Height') || "";

							const initialProductShippingDimensionsL = sheetRow?.get('Shipping Length') || "";
							const initialProductShippingDimensionsW = sheetRow?.get('Shipping Width') || "";
							const initialProductShippingDimensionsH = sheetRow?.get('Shipping Height') || "";

							const initialNotes = sheetRow?.get('Notes') || "";

							return <div key={ product.id + "_" + index } className="tw-flex tw-flex-col tw-w-full tw-gap-7px tw-border-b-1px tw-border-b-white/20 tw-pb-40px">
								<div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-15px tw-pb-10px tw-pt-10px tw-sticky tw-top-0px tw-z-2 tw-bg-[#292826]">
									<div className="tw-flex tw-items-start tw-w-1/2 md:tw-w-100px tw-flex-shrink-0">
										<label for={`count_field_${product.id}_${index}`} className="tw-cursor-pointer tw-select-none tw-w-full tw-border-1px tw-bg-secondary tw-text-white tw-rounded-6px tw-overflow-clip tw-border-black">
											<AspectImage src={ product.images[0]?.src } alt={ product.title } className="tw-w-full" aspect="16/9" />
										</label>
									</div>
									<div className="tw-flex-grow tw-flex tw-flex-col">
										<ArticleHeader className="tw-pt-5px tw-text-primary [&>span_span]:tw-normal-case [&_sup]:tw-text-[.5em] [&_sup]:tw-top-[-.75em] [&_sup]:tw-font-quaternary-lite">
											<span dangerouslySetInnerHTML={{ __html: wrapTextArray(wrapText(stringToArray(product.title)[0],"®","sup"),["2200i","3330i","4500ies","4500ies","5500ies","4000i","5500i","9000i","LifePO4","LiFePO4"],"span") }}></span>
										</ArticleHeader>
										<span className="tw-w-full tw-font-quaternary-light tw-uppercase tw-text-[.75em]">
											SKU/Model #: <span className="tw-font-mono tw-text-[.9em]">{ product.variants[0]?.sku || "N/A" }</span>
										</span>
									</div>
								</div>
								<div data-fieldset={ product.id }>
									<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-5px tw-gap-y-10px tw-grid-rows-2 tw-items-end tw-flex-grow">
										<div>
											<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialProductWeight } value={ initialProductWeight === 0 ? '' : initialProductWeight } id={`count_field_${product.id}_${index}`} data-column="Product Weight" data-product={ product.id } label='<span>Product Weight</span>' placeholder="#" onChange={ handleUpdate } />
										</div>
										<div>
											<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialShippingWeight } value={ initialShippingWeight === 0 ? '' : initialShippingWeight } id={`count_field_${product.id}_${index}`} data-column="Shipping Weight" data-product={ product.id } label='<span>Shipping Weight</span>'  placeholder="#" onChange={ handleUpdate } />
										</div>
										<div>
											<Label htmlFor={`count_field_${product.id}_${index}1`} className="tw-text-primary">
												Product Dimensions
											</Label>
											<div className="tw-grid tw-gap-0 tw-grid-cols-3">
												<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialProductDimensionsL } value={ initialProductDimensionsL === 0 ? '' : initialProductDimensionsL } id={`count_field_${product.id}_${index}1`} data-column="Product Length" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">L</span>' placeholder="Length" onChange={ handleUpdate } />
												<Field type="tel" format="replaceChar" className="tw-flex-grow [&>.tw-border-1px]:tw-border-x-0px" initValue={ initialProductDimensionsW } value={ initialProductDimensionsW === 0 ? '' : initialProductDimensionsW } id={`count_field_${product.id}_${index}2`} data-column="Product Width" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">W</span>' placeholder="Width" onChange={ handleUpdate } />
												<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialProductDimensionsL } value={ initialProductDimensionsH === 0 ? '' : initialProductDimensionsH } id={`count_field_${product.id}_${index}3`} data-column="Product Height" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">H</span>' placeholder="Height" onChange={ handleUpdate } />
											</div>
										</div>

										<div>
											<Label htmlFor={`count_field_${product.id}_${index}1`} className="tw-text-primary">
												Shipping Box Dimensions
											</Label>
											<div className="tw-grid tw-gap-0 tw-grid-cols-3 ">
												<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialProductShippingDimensionsL } value={ initialProductShippingDimensionsL === 0 ? '' : initialProductShippingDimensionsL } id={`count_field_${product.id}_${index}4`} data-column="Shipping Length" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">L</span>'  placeholder="Box Length" onChange={ handleUpdate } />
												<Field type="tel" format="replaceChar" className="tw-flex-grow [&>.tw-border-1px]:tw-border-x-0px" initValue={ initialProductShippingDimensionsW } value={ initialProductShippingDimensionsW === 0 ? '' : initialProductShippingDimensionsW } id={`count_field_${product.id}_${index}4`} data-column="Shipping Width" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">W</span>'  placeholder="Box Width" onChange={ handleUpdate } />
												<Field type="tel" format="replaceChar" className="tw-flex-grow" initValue={ initialProductShippingDimensionsH } value={ initialProductShippingDimensionsH === 0 ? '' : initialProductShippingDimensionsH } id={`count_field_${product.id}_${index}4`} data-column="Shipping Height" data-product={ product.id } label='<span className="tw-w-full tw-block tw-text-center">H</span>'  placeholder="Box Height" onChange={ handleUpdate } />
											</div>
										</div>
									</div>
									<div className="tw-pt-10px">
										<Field type="textarea" className="tw-flex-grow" initValue={ initialNotes } value={ initialNotes || "" } id={`count_field_${product.id}_${index}`} data-column="Notes" data-product={ product.id } label='<span>Notes</span>'  placeholder="Add any notes (if needed)" onChange={ handleUpdate } />
									</div>
								</div>
								<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-end tw-pt-10px">
									<ButtonPrimary className="tw-w-full md:tw-w-auto" default onClick={ handleSave } data-product={ product.id }>
										<span className="tw-inline-flex tw-text-4 tw-px-20px tw-py-5px tw-min-h-30px tw-min-w-120p tw-w-full tw-text-center tw-justify-center">
											<span>
												Save
											</span>
										</span>
									</ButtonPrimary>
									<div className="tw-pointer-events-none tw-select-none tw-flex tw-px-10px tw-justify-center tw-text-dynamic tw-tracking-wider tw-leading-none tw-uppercase tw-font-quaternary tw-font-quaternary-lite md:tw-hidden" style={{ '--min-font-size': 8, '--max-font-size': 12 }}>
										<br />
										{ product.title }
									</div>
								</div>
							</div>
						}) : <>
							No results
						</> }
					</div> }
					{/* <div className="tw-fixed tw-w-full tw-left-0px tw-flex tw-px-40px tw-py-20px tw-justify-center tw-top-[100vh] -tw-translate-y-full tw-z-3 tw-bg-white tw-border-1px tw-border-black">
						<Button>
							Save
						</Button>
					</div> */}
				</form>
			</section>
		</div>
	</>
}
