import { useContext, createContext, useMemo, useState } from "react";

const AppContext = createContext({
	products: null,
	orderLabels: null,
	setOrderLabels: (() => undefined),
	setProducts: (() => undefined),
	currentTime: null,
	setCurrentTime: (() => undefined),
	loggedTime: null,
	setLoggedTime: (() => undefined),
	timeRemaining: 59,
	setTimeRemaining: (() => undefined),
	rows: 0,
	setRows: (() => undefined),
	currentView: null,
	setCurrentView: (() => undefined),
	globalCount: 0,
	setGlobalCount: (() => undefined),
	fsUnits: null,
	setFsUnits: (() => undefined),
	setQCFields: (() => undefined)
});

export const useAppContext = () => useContext(AppContext);

export const getLoggedtime = () => {
	const loggedTime = localStorage.getItem("loggedTime");
	return loggedTime || null;
}

export const AppProvider = ({ children }) => {
	const [products, setProducts] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const [timeRemaining, setTimeRemaining] = useState(59);
	const [loggedTime, setLoggedTime] = useState(getLoggedtime());
	const [rows, setRows] = useState(59);
	const [orderLabels, setOrderLabels] = useState(null);
	const [currentView, setCurrentView] = useState("rvmp");
	const [globalCount, setGlobalCount] = useState(0);
	const [fsUnits, setFsUnits] = useState(0);
	const [qcFields, setQCFields] = useState(0);

	const value = useMemo(() => ({
		products, setProducts,
		currentTime, setCurrentTime,
		loggedTime, setLoggedTime,
		timeRemaining, setTimeRemaining,
		rows, setRows,
		orderLabels, setOrderLabels,
		currentView, setCurrentView,
		globalCount, setGlobalCount,
		fsUnits, setFsUnits,
		qcFields, setQCFields
	}), [fsUnits, currentView, products, currentTime, loggedTime, timeRemaining, rows, orderLabels, globalCount]);

	return (
		<AppContext.Provider value={ value }>
			{ children }
		</AppContext.Provider>
	);
}
