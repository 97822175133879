import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { coreFieldStyles, Field, Input, Textarea } from "../../../components/forms";
import { ButtonLight } from "../../../components/ctas";
import { useAppContext } from "../../../contexts/app";
import { FieldsetWrapperFS, QCSubNav } from "../unit/page";
import { removeSpecialCharacters } from "../../../utils/formatting";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { generateUUID } from "../../../utils/global";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { PDIchecklist } from "../data/PDIchecklist";


export const removeUnderscore = (data) => {
    const newData = {};
    for (const key in data) {
        const newKey = key.split('_').map((word, index) => {
            if (index === 0) {
                return word;
            }
            return word[0]?.replaceAll(' X ', ' > ').replaceAll('_X_', ' > ') + word?.slice(1).replaceAll(' X ', ' > ').replaceAll('_X_', ' > ');
        }).join(' ');
        newData[newKey.replaceAll(' X ', ' > ').replaceAll('_X_', ' > ')] = data[key];
    }
    return newData;
}

// add all progress fields and set to 0
const initProgress= (data) => {
	return data.map((section) => {
		section.progress = 0.00;
		return section;
	});
}

const CheckboxItem = props => {
	const handleClick = () => {
		props.setActiveIndex(props.index);
	}

	return <>
		<input id={props.id + props.index} value={props.value.replace("/", "")} type="radio" name={props.id} required={props.required} className="tw-hidden tw-w-0px tw-h-0px tw-appearance-none checked:tw" />
		<label htmlFor={props.id + props.index} onClick={handleClick} className={`${props.activeIndex === props.index ? 'tw-bg-white tw-text-black || tw-pointer-events-none tw-cursor-default' : 'tw-bg-black tw-text-white ||  tw-cursor-pointer'} tw-border-1px tw-border-solid || tw-min-h-40px tw-flex tw-flex-grow tw-items-center tw-justify-center tw-uppercase tw-font-quaternary tw-font-quaternary-bold`}>
			{props.value}
		</label>
	</>
}

const CheckBox = ({ id, name, required, handleCheckbox, handleField, values }) => {
	const formId = id || name.replace(/\s/g, '_').toLowerCase();
	const [activeIndex, setActiveIndex] = useState(null);
	const [addDetails, setAddDetails] = useState(false);

	const indexMap = number => {
		if (number === 1) {
			return "Yes";
		} else if (number === 2) {
			return "N/A";
		} else if (number === 3) {
			setAddDetails(true);
			return "No";
		} else {
			return null;
		}
	}

	const indexMapReverse = value => {
		if (value === "Yes") {
			return 1;
		} else if (value === "N/A") {
			return 2;
		} else if (value === "No") {
			return 3;
		} else {
			return null;
		}
	}

	useEffect(() => {
		if (activeIndex) {
			handleCheckbox(formId, indexMap(activeIndex));
		}
	}, [activeIndex]);

	const handleAddDetails = (e) => {
		if (e) e.preventDefault();
		setAddDetails(!addDetails);
	}

	useLayoutEffect(() => {
		if (values[`${ formId }`]) {
			if (values[`${ formId }_Details`]) setAddDetails(true);
			setActiveIndex(indexMapReverse(values[`${ formId }`]));
		}
	}, [values]);

	return <>
		<div>
			<div className="tw-grid tw-grid-cols-3 tw-py-5px tw-w-full">
				<CheckboxItem activeIndex={activeIndex} setActiveIndex={setActiveIndex} name={name} id={formId} index={1} required={required} value="Yes" />
				<CheckboxItem activeIndex={activeIndex} setActiveIndex={setActiveIndex} name={name} id={formId} index={2} required={required} value="N/A" />
				<CheckboxItem activeIndex={activeIndex} setActiveIndex={setActiveIndex} name={name} id={formId} index={3} required={required} value="No" />
			</div>
			<div className={`tw-transition-all tw-duration-300 tw-delay-50 tw-overflow-clip ${activeIndex === 1 || activeIndex === 2 ? 'tw-max-h-40px' : 'tw-max-h-0px'} `}>
				<ButtonLight onClick={e => handleAddDetails(e)} className="tw-min-h-25px tw-items-center">
					<span className="tw-text-[.7em] tw-uppercase tw-font-quaternary tw-font-quaternary-bold tw-tracking-wide tw-px-10px tw-items-center tw-flex tw-w-full">
						{addDetails ? 'Hide ' : 'Add '} Details
					</span>
				</ButtonLight>
			</div>
			{addDetails == true && <div>
				<Textarea value={ values[`${ formId }_Details`] || "" } onInput={handleField} id={formId + "_Details"} required={activeIndex === 3 ? "required" : null} placeholder={activeIndex === 3 ? `Provide Details for Why It Did Not Pass` : `Provide Details`} className="tw-w-full" />
			</div>}
		</div>
	</>

}

const sentenceCase = str => str.split(" ").map(word => word[0].toUpperCase() + word.slice(1)).join(" ");

/*
export const makeValues = (obj) => {
	const temp = {};
	const promises = fieldDataFSUnits.map((section, index) => {
		return section.items.map((item, itemIndex) => {
			temp[item.name] = obj.get(item.name);
		});
	});

	temp.UUID = obj.get('UUID');
	return Promise.all(promises).then(() => temp);
}
*/

export const makeValues = (obj) => {
	const temp = {};
	const promises = PDIchecklist.map((group, index) => {
		const tempGroup = group.group;
		group.items.map((category) => {
			const tempCategory = category.category;
			category.items.map((item) => {
				const tempItem = `${tempGroup} > ${tempCategory} > ${item.name}`;
				const dataField = obj.get(tempItem);
				if (dataField) {
					const dataFieldDetails = obj.get(tempItem + " Details");
					temp[removeSpecialCharacters(tempItem.replaceAll(" > ","_X_"),true)] = obj.get(tempItem);
					if (dataFieldDetails) temp[removeSpecialCharacters(tempItem.replaceAll(" > ","_X_"),true) + "_Details"] = dataFieldDetails;
				}
			});
		});
	});
	temp.UUID = obj.get('UUID');
	return Promise.all(promises).then(() => temp);
}

const SelectUnit = props => {
	const navigate = useNavigate();
	const [rowsOfUnits, setRowsOfUnits] = useState([]);
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_FS_UNITS });

	//Loads the sheet and sets the values, otherwise creates new row (sets UUID)
	useLayoutEffect(() => {
		loadSheet(true).then((sheets) => {
			setRowsOfUnits(sheets);
		});
	}, []);

	const handleUnit = e => {
		if (e?.target.value.startsWith("UUID_")) {
			window.location.href = `/fs/qc/${e?.target.value.replace("UUID_", "")}`;
			window.scrollTo(0, 0);
		} else {
			props.setValues({ ...props.values,
				"Unit Name": e?.target?.value,
				"Model": e?.target?.dataset?.model || "--",
				"VIN": e?.target?.dataset?.vin || "--"
			});
		}
	}

	const findUnitByName = (data, name) => {
		for (let i = 0; i < data?.length; i++) {
			if (data[i]["Unit Name"] === name) {
				return data[i].UUID;
			}
		}
		return null
	}

	return <>
		<FieldsetWrapperFS title="Unit">
			<label htmlFor="units" className="tw-uppercase tw-tracking-[.1em] tw-text-[0.9em] tw-w-full tw-font-quaternary tw-font-quaternary-bold">
				Select Unit
			</label>
			<select id="unitsList" disabled={ props.editMode ? "disabled" : null } className="tw-text-black || coreFieldStyles + `|| tw-indent-10p tw-px-0px tw-py-10p placeholder:tw-text-[.5em] md:placeholder:tw-text-[.65em]`}" onChange={handleUnit}>
				{ props.editMode ? <option value={props.name} selected label={props.name} data-model={props.model} data-vin={props.vin}> {props.name} </option> :
				<option value="" label="--Select Unit--" className="tw-text-[.5em] md:tw-text-[.65em]">--Select Unit --</option> }

				{ rowsOfUnits.map((row, index) => {
					const unitName = row.get('Unit Name');
					const uuid =  findUnitByName( props.qcItems, unitName ) || null;

					return unitName && <option key={`unit_${index}`} value={ uuid ? "UUID_" + uuid : row.get('Unit Name') || ''} data-model={ row.get('Model')} data-vin={ row.get('VIN')} data-uuid={ uuid || null }>  { unitName } { uuid || "" } </option>
				})}
			</select>
		</FieldsetWrapperFS>
	</>
}

export default function FSQC() {
	const { id } = useParams();
	const appContext = useAppContext();
	const formRef = useRef();
	const [values, setValues] = useState(null);
	const [rows, setRows] = useState([]);
	const [rowsOfUnits1, setRowsOfUnits1] = useState([]);
	const [qcItems, setQcItems] = useState([]);
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_FS_PDI });
	const [editMode, setEditMode] = useState(false);
	const [uuid, setUuid] = useState(null);
	const { currentUser } = useAuth();
	const navigate = useNavigate();
	const [fields, setFields] = useState(PDIchecklist);

	const addRowToSheet = (obj, message = "Added row to sheet") => {
		return new Promise((resolve, reject) => {
			addRow(obj);
			resolve(message);
		});
	}

	const updateRowInSheet = (obj, message = "Updated row in sheet") => {
		return new Promise((resolve, reject) => {
			loadSheet(true).then((sheets) => {
				// let row = sheets?.find(row => row._rowNumber === obj.index);
				let row = sheetRows?.find( row => row.get('UUID') === obj?.UUID  );

				if (row) {
					const promises = Object.keys(obj).map(key => setRow(row, key, obj[key]));
					Promise.all(promises).then(() => {
						saveRow(row);
						resolve(message);
					});
				} else {
					alert("Could not locate the row")
				}
			});
		});
	}

	const saveSuccess = (target = null, url = null) => {
		if (target) {
			setTimeout(() => {
				target?.classList.remove("_loading")
				target?.classList.add("_success");
				setTimeout(() => {
					target?.classList.remove("_success");
					setEditMode(true);

					if (url) {
						window.scrollTo(0, 0);
						window.location.href = url;
					}
				}, 1000);
			}, 750);
		}
	}

	useLayoutEffect(() => {
		if (uuid && !values?.UUID) {
			setValues({
				...values,
				"UUID": uuid
			});
		}
	}, [uuid, values]);

	const handleSave = (e, update = editMode) => {
		if (e) e.preventDefault();

		const target = e?.target;
		if (target) target.classList.add("_loading");

		// Add disabled fields to values & set default values for blank fields
		const updatedValues = { ...values }; // Start with the current values state
		PDIchecklist.forEach((group) => {
			const groupTemp = group.group;
			group.items.forEach((item) => {
				const categoryTemp = item.category;
				const itemName = `${groupTemp} > ${categoryTemp} > ${item.name}`;
				const itemNameFieldName = removeSpecialCharacters(itemName.replaceAll(" > ", "_X_"),true);

				const valueIsBlank = updatedValues[itemNameFieldName] === '' || updatedValues[itemNameFieldName] === null;
				if (valueIsBlank && item.defaultValue) {
					// If the value is blank and a defaultValue exists, set it
					updatedValues[itemNameFieldName] = item.defaultValue;
				}

				// Check if the item is disabled
				const isDisabled = Array.isArray(item.disabled) && item.disabled.some(disabledItem => disabledItem.disabled === "true");
				if (isDisabled && !updatedValues[itemNameFieldName]) {
					// Add the default value for the disabled field
					const defaultValue = item.disabled.find(disabledItem => disabledItem.defaultValue)?.defaultValue || '';
					updatedValues[itemNameFieldName] = defaultValue;
				}
			});
		});

		// Construct the tempEntries object with updatedValues
		const tempEntries = update
			? {
				  ...updatedValues, // Use updatedValues here
				  "Updated On": new Date().toISOString(),
				  "Updated By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			  }
			: {
				  ...updatedValues, // Use updatedValues here
				  "UUID": uuid,
				  "Created On": new Date().toISOString(),
				  "Updated On": new Date().toISOString(),
				  "Created By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
				  "Updated By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			  };

		const entries = removeUnderscore(tempEntries);

		if (update) {
			// Update row
			updateRowInSheet(entries, `Updated row ${JSON.stringify(entries, null, 2)} in sheet`).then((message) => {
				console.log(message);
				saveSuccess(target);
			});
		} else {
			// Add new row
			addRowToSheet(entries, `Added row ${JSON.stringify(entries, null, 2)} in sheet`).then((message) => {
				console.log(message);
				saveSuccess(target);

				document.querySelector("html").classList.add("reloading");

				setTimeout(() => {
					alert("Great success!\nRedirecting to the new URL...");
					document.querySelector("html").classList.remove("reloading");
					// Redirect to the new URL
					window.location.href = `/fs/qc/${uuid}`;
					window.scrollTo(0, 0);
				}, 1500);
			});
		}
	}

	const createObj = (rowsOfUnits1) => {
		const temp =[];
		return new Promise((resolve, reject) => {
			rowsOfUnits1.forEach((row) => {
				// push to temp array
				temp.push({
					"UUID": row.get('UUID'),
					"Unit Name": row.get('Unit Name'),
					"Model": row.get('Model'),
					"VIN": row.get('VIN')
				});
				resolve(temp);
			});
		});
	}


	useLayoutEffect(() => {
		if (rowsOfUnits1.length) {
			rowsOfUnits1.forEach((row) => {
				createObj(rowsOfUnits1).then((data) => {
					setQcItems(data);
				})
			});
		}
	}, [rowsOfUnits1]);

	// useLayoutEffect(() => {
	// 	loadSheet(true).then((sheets) => {
	// 		setRows(sheets);
	// 		setRowsOfUnits1(sheets);
	// 	});
	// }, []);

	useEffect(() => {
		if (rows?.length) {
			appContext.setQCFields(rows);
		}
	}, [appContext, rows]);

	//Loads the sheet and sets the values, otherwise creates new row (sets UUID)
	useLayoutEffect(() => {
		loadSheet(true).then((sheets) => {
			setRows(sheets);
			setRowsOfUnits1(sheets);

			if (id) {
				let row = sheets?.find( row => row.get('UUID') === id.toString() );
				makeValues(row).then((values) => {
					// console.log('makeValues', values);
					setValues({...values,
						"UUID": row.get('UUID'),
						"Unit Name": row.get('Unit Name'),
						"Model": row.get('Model'),
						"VIN": row.get('VIN'),
						"Notes": row.get('Notes')
					});
					// setValues(values);
					setUuid(values.UUID);
					setEditMode(true);
					return row;
				});
			} else {
				setUuid(generateUUID());
			}
		});
	}, []);

	// used on unit selection datalist
	const handleUnit = e => {
		const target = e.target;
		// if (target.value.replaceAll(" ", "").length > 0 && (target.value.toLowerCase().includes("alpha") || target.value.toLowerCase().includes("bravo") || target.value.toLowerCase().includes("pogo"))) {
		// 	setValues({ ...values, "Unit": target.value });
		// } else {
		// 	setValues({ ...values, "Unit": null });
		// }
		// navigate(`/fs/qc/${target.value}`);

		if (target.value.replaceAll(" ", "").length > 0) {
			setValues({ ...values, "Unit Name": target.value });
		}
	}

	// used on all inputs
	const handleField = e => {
		const target = e.target;
		setValues({ ...values, [sentenceCase(target.id).replaceAll(" ", "_")]: target.value });
	}

	// used on checkboxes
	const handleCheckbox = (id, value) => {
		setValues({ ...values, [sentenceCase(id).replaceAll(" ", "_")]: value });
	}

	// //Loads the sheet and sets the values, otherwise creates new row (sets UUID)
	// useLayoutEffect(() => {
	// 	loadSheet(true).then((sheets) => {
	// 		if (id) {
	// 			let row = sheets?.find( row => row.get('UUID') === id.toString() );
	// 			makeValues(row).then((values) => {
	// 				setValues(values);
	// 				setUuid(values.UUID);
	// 				setEditMode(true);
	// 			});
	// 		} else {
	// 			setUuid(generateUUID());
	// 			setRows(sheets);
	// 		}
	// 	});
	// }, []);


	useLayoutEffect(() => {
		initProgress(fields);

		// const x = () => {
		// 	let csv = "";
		// 	PDIchecklist.forEach((group) => {
		// 		const groupTemp = group.group;
		// 		group.items.forEach((category) => {
		// 			category.items.forEach((item) => {
		// 				csv += `${groupTemp} > ${category.category} > ${item.name}\n`;
		// 				csv += `${groupTemp} > ${category.category} > ${item.name} Details\n`;
		// 			});
		// 		});
		// 	});
		// 	return csv;
		// }

		//   console.log(x());

	}, []);

	const handleNotes = e => {
		const target = e.target;
		setValues({ ...values, "Notes": target.value });
	}

	const subNavData = [{
		title: "Router",
		progress: 1
	}, {
		title: "Camera",
		progress: 0.75
	}, {
		title: "Speaker",
		progress: 0.4
	}, {
		title: "Victron",
		progress: 0.00
	}, {
		title: "Generator",
		progress: 0.00
	}]

	return <>

		<div className="tw-w-full tw-px-40d tw-flex tw-space-x-40d">
			<div className="">
				<QCSubNav subNavData={ subNavData } />
			</div>
			<form ref={formRef} className="tw-w-full tw-space-y-40px tw-pb-60px">

				{ editMode ? <>
					<SelectUnit editMode={ editMode} values={ values } setValues={ setValues } uuid={ values.UUID } model={ values.Model } name={ values?.["Unit Name"] } vin={ values?.VIN} qcItems={ qcItems }></SelectUnit>
				</> : <>
					<SelectUnit values={ values } setValues={ setValues } qcItems={ qcItems }></SelectUnit>
				</>}

				{ PDIchecklist && values?.UUID && <>
					<div className="tw-space-y-30px">
						{ PDIchecklist.map((group, index) => {
							const tempGroup = group.group;
							return <div key={`checkbox_pdi_group_${index}`} className="tw-space-y-20px">
								<div className="tw-bg-black tw-text-center tw-w-full">
									<h2 className="tw-text-dynamic tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-tracking-[.1em]" style={{ "--min-font-size": 1, "--max-font-size": 40 }}>
										{ group.group }
									</h2>
								</div>
								{ group.items.map((category, index) => {
									const tempCategory = removeSpecialCharacters(category.category,true);
									return <FieldsetWrapperFS title={category.category} key={`checkbox_pdi_category_${index}`}>
										{ category.items.map((item, index) => {
											const tempID = removeSpecialCharacters(tempGroup + "_X_" + tempCategory + "_X_" + item.name,true);

											return <fieldset className="tw-flex tw-flex-col tw-w-full" key={`checkbox_pdi_${index}`}>
												<label htmlFor={tempID} className="tw-uppercase tw-tracking-[.1em] tw-text-[0.9em] tw-w-full tw-font-quaternary tw-flex tw-flex-col [&_>span]:tw-w-full tw-font-quaternary-normal">
													<span>
														{item.name}
													</span>
													{item.description && <span className="tw-normal-case tw-text-[.75em] tw-text-white/60">
														{item.description}
													</span>}
												</label>
												<CheckBox values={ values } handleField={handleField} id={tempID} handleCheckbox={handleCheckbox} name={item.name} required={item.required} index={index} />
											</fieldset>
										}) }
										<div className="tw-flex tw-justify-end">
											<ButtonLight type="submit" className="tw-uppercase" default  onClick={handleSave}>
												<span className="tw-text-[1rem] tw-py-10px tw-px-10px tw-w-full tw-flex tw-items-center tw-justify-center">
													<span>
														Save
													</span>
												</span>
											</ButtonLight>
										</div>
									</FieldsetWrapperFS>
								})}
							</div>
						})}
						<div className="tw-bg-black tw-text-center tw-w-full">
							<h2 className="tw-text-dynamic tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-tracking-[.1em]" style={{ "--min-font-size": 1, "--max-font-size": 40 }}>
								Additional
							</h2>
							<FieldsetWrapperFS title="Additional Info">
								<Field type="textarea" label="Notes" id="Notes"  value={ values?.Notes || "" } onInput={ handleNotes } />
							</FieldsetWrapperFS>
						</div>
					</div>
					<div className="tw-w-full tw-flex tw-justify-end">
						<ButtonLight type="submit" className="tw-uppercase" default  onClick={handleSave}>
							<span className="tw-text-[1.5em] tw-py-5em tw-px-20em tw-w-full tw-flex tw-items-center tw-justify-center">
								Submit
							</span>
						</ButtonLight>
					</div>
				</>}
			</form>
		</div>
	</>
}
