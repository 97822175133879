
import { Link, useLocation } from "react-router-dom"
import Wrapper from "./wrappers"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { debounce, timeDiff, vh } from "../utils/global"
import { useAuth } from "../hooks/useAuth"
import { useAppContext } from "../contexts/app"
import { AspectImage } from "./assets"

export const LogoutLink = ({ base, currentUser, logout }) => {
	const { timeRemaining } = useAppContext();

	return <Link to="/" onClick={logout} title={`Logout of ${ currentUser.userinfo?.name }`}>
		<span className="tw-flex tw-items-center tw-w-full tw-space-x-5px">
			<span>Logout</span>
			{ base ? <></> : <span className="tw-text-[.7em] tw-flex tw-flex-col tw-leading-none tw-items-center"><b className="">{ timeRemaining }</b> <span className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-[0.4375rem]">minutes <span>remaining</span></span></span> }
		</span>
	</Link>
}

export const Header = ({tout, ...props}) => {
	const headerRef = useRef(null);
	const { timeRemaining } = useAppContext();
	const { currentUser, logout } = useAuth();

	const setHeaderHeight = (el) => {
		const header = el || document.querySelector("#header");
		document.documentElement.style.setProperty("--header-height", `${(header)?.offsetHeight || 0}px`);
	}

	useEffect(() => {
		if (headerRef?.current) {
			setTimeout(() => {
				setHeaderHeight(headerRef?.current)
			}, 0);
			if (window) window.addEventListener("resize", debounce(setHeaderHeight(headerRef?.current), 30));
		}
	}, [headerRef]);

	return <>
		{ tout && currentUser && <>
			{ Number(timeRemaining) > 1 && <>
				<div className=" tw-bg-accent-dark tw-justify-center tw-text-white tw-text-4 tw-py-10px tw-px-20px tw-text-center tw-fixed tw-w-full tw-top-0 tw-right-0 tw-left-0 tw-z-10">
					<p>
						{ tout }
					</p>
					<div className="tw-flex tw-w-full tw-justify-center tw-uppercase tw-font-quaternary tw-font-quaternary-black">
						<LogoutLink base currentUser={ currentUser } logout={ logout } />
					</div>
				</div>
			</> }
		</> }
		<header ref={ headerRef } id="header" className="tw-bg-black tw-border-b tw-border-b-white/10 tw-text-white tw-select-none tw-shadow-sm">
			<Wrapper className="tw-py-20px tw-space-x-40d [&>a]:tw-w-full [&_a]:tw-inline-flex [&_a]:tw-text-inherit [&_a]:tw-items-center [&_a]:tw-justify-center [&_a]:tw-text-white [&_a]:tw-transition-all [&_a]:tw-duration-200 hover:[&_a]:tw-text-primary tw-leading-[.9] tw-font-quaternary tw-font-quaternary-black tw-tracking-tight tw-uppercase" tag="nav">
				<div className="tw-flex tw-gap-40d">
					<div className="[&_a]:tw-inline-flex tw-max-w-70px tw-flex-shrink-0">
						<Link to="/">
							<AspectImage src={`/images/rvmp-logo-bordered.svg`} alt="RVMP Logo" className="tw-w-full" aspect="1/1" />
						</Link>
					</div>
					<ul className="tw-flex-grow tw-flex [&>li]:tw-inline-flex tw-text-dynamic tw-gap-10d md:tw-gap-40d" style={{ '--min-font-size': 10, '--max-font-size': 20 }}>
						<li>
							<Link to="/dashboard/inventory">
								Inventory Tracker
							</Link>
						</li>
						<li>
							<Link to="/dashboard/weight-dims">
								Size &amp; Weight
							</Link>
						</li>
						{/* <li>
							<Link to="/dashboard/order-labels">
								Order Labels
							</Link>
						</li> */}
						<li className="tw-ml-auto">
							<span className="tw-flex">
								{ currentUser ? <LogoutLink currentUser={ currentUser } logout={ logout } /> : <Link to="/login" className="tw-text-inherit">Login</Link> }
							</span>
						</li>
					</ul>
				</div>
			</Wrapper>
		</header>
	</>
}


export const HeaderFS = ({tout, ...props}) => {
	const { currentUser, logout } = useAuth();
	return <>
		<div className="tw-w-full tw-px-20px tw-flex tw-justify-start tw-py-20px tw-shadow-drop-b-lg">
			<div className="tw-w-full tw-max-w-300d tw-pr-30px tw-pl-40px">
				<AspectImage src={`/images/flex-security.svg`} aspect="514/74" className="tw-w-full tw-max-w-300d" />
			</div>
			<div className="tw-flex-grow tw-text-dynamic-up tw-leading-none tw-flex tw-items-center tw-tracking-[.1em] tw-uppercase tw-font-quaternary tw-font-quaternary-thin" style={{"--min-font-size": 30, "--max-font-size": 40}}>
				<span className="tw-inline-block tw-leading-none">
					PDI + Quality Control
				</span>
			</div>
			<div className="tw-ml-auto">
				<span className="tw-flex tw-tracking-[.1em] tw-uppercase tw-font-quaternary tw-font-quaternary-thin">
					{ currentUser ? <LogoutLink currentUser={ currentUser } logout={ logout } /> : <Link to="/login" className="tw-text-inherit">Login</Link> }
				</span>
			</div>
		</div>
	</>
}


export const Base = props => {
	const { logout } = useAuth();
	const { currentTime, loggedTime, setCurrentTime, setTimeRemaining, currentView, setCurrentView } = useAppContext();
	const  [tout, setTout] = useState(null);

	setTimeout(() => {
		setCurrentTime(new Date().toISOString())
	}, 58000);

	useLayoutEffect(() => {
		setCurrentTime(new Date().toISOString())
		vh();
	}, []);


	useEffect(() => {
		if ( currentTime && loggedTime ) {
			const timeDifference = timeDiff(currentTime, loggedTime, true);

			if (localStorage.getItem('timeDifference') === null) {
				localStorage.setItem('timeDifference', 59 - timeDifference);
				setTimeRemaining(59 - timeDifference);
			} else {
				setTimeRemaining(Number(localStorage.getItem('timeDifference')) - timeDifference);
			}

			if (timeDifference >= 54) {
				setTout(`You must login again within the next ${ 59 - timeDifference } minutes`);
			}

			if (timeDifference >= 59) {
				setTout(null);
				logout();
			}
		}
	}, [currentTime, loggedTime]);


	const location = useLocation();
	useEffect(() => {
		setCurrentView( location.pathname.includes("/fs") ? "fs" : "rvmp");
	  }, [location]);


	return <>
		{ currentView === "rvmp" ? <>
			<div className="tw-flex tw-flex-col tw-min-h-screen tw-w-full tw-overflow-x-clip">
				<Header tout={ tout || null }  />
				<Wrapper className="tw-py-40px tw-flex-grow" tag="main">
					{ props.children }
				</Wrapper>
			</div>
		</> : <>
			<div className="tw-min-h-[100svh] tw-bg-secondary tw-flex-grow tw-font-quaternary tw-font-quaternary-thin tw-tracking-wide tw-flex tw-flex-col tw-w-full">
				<HeaderFS tout={ tout || null } />
				<div className="tw-flex tw-flex-row tw-w-full tw-flex-grow tw-border-t-1px tw-border-t-white/5 tw-z-2">
					<div className="tw-bg-[#292927] tw-min-w-300d tw-flex-shrink-0 tw-border-r-1px tw-border-r-white/5  tw-shadow-drop-r-lg tw-bg- tw-flex tw-flex-row">
						<div className="tw-bg-secondary tw-w-40px tw-flex-grow tw-flex tw-flex-col">
							<ul className="tw-flex-grow tw-pt-20px  tw-sticky tw-mt-20px tw-mb-auto tw-top-30px tw-justify-between tw-flex tw-flex-col tw-z-2 tw-relative -tw-mr-10px tw-pr-5px tw-max-h-[25vh]">
								<li className="tw-flex tw-items-center tw-justify-center tw-bg-[#292927] tw-py-20px">
									<AspectImage className="tw-w-20px tw-bg-white tw-rounded-3px tw-overflow-hidden" />
								</li>
								<li className="tw-flex tw-items-center tw-justify-center  tw-py-20px">
									<AspectImage className="tw-w-20px tw-bg-white tw-rounded-3px tw-overflow-hidden" />
								</li>
								<li className="tw-flex tw-items-center tw-justify-center  tw-py-20px">
									<AspectImage className="tw-w-20px tw-bg-white tw-rounded-3px tw-overflow-hidden" />
								</li>
							</ul>
						</div>
						<ul className="tw-w-[calc(100%-2.5rem)] tw-sticky tw-mt-20px tw-mb-auto tw-top-30px tw-flex tw-flex-col tw-uppercase [&_a]:tw-flex [&_li]:tw-flex [&_a]:tw-flex-grow [&_li]:tw-flex-grow tw-tracking-widest tw-font-quaternary tw-font-quaternary-normal">
							<li>
								<Link to="/fs">
									<span className="tw-px-20px tw-py-5px tw-flex-grow tw-pointer-events-none">
										<i className="fa-solid fa-table-columns"></i> Dashboard
									</span>
								</Link>
							</li>
							<li>
								<Link to="/fs/qc">
									<span className="tw-px-20px tw-py-5px tw-flex-grow tw-pointer-events-none">
										<i className="fas fa-eye"></i> QC
									</span>
								</Link>
							</li>
							<li>
								<Link to="/fs/inventory">
									<span className="tw-px-20px tw-py-5px tw-flex-grow tw-pointer-events-none">
										<i className="fa-solid fa-sitemap"></i> Units
									</span>
								</Link>
							</li>
							<li>
								<Link reloadDocument to="/fs/unit" className="tw-px-20px tw-py-5px">
									<span className="tw-px-20px tw-py-5px tw-flex-grow tw-pointer-events-none tw-border tw-border-1px tw-w-full tw-flex tw-justify-center tw-items-center tw-tracking-wider tw-font-quaternary tw-font-quaternary-bold">
										Add Unit
									</span>
								</Link>
							</li>
						</ul>
					</div>
					<div className="tw-flex-grow tw-pt-20px tw-px-20px">
						{ props.children }
					</div>
					<div className="tw-min-w-300d tw-flex-shrink-0"></div>
				</div>
			</div>
		</> }
	</>
}

export default Base;
