import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../contexts/app";
import { AspectImage } from "../../../components/assets";
import jsPDF from 'jspdf';
import { replaceChar } from "../../../utils/formatting";
import { getObjectByIndex, wrapText } from "../../../utils/global";


const Card = ({ index, ID, OEM, Delivery_Date, Number_Items, Collection, Count, Quantity, Model }) => {
	return <>
		<div className="xtw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full  tw-bg-white tw-text-white [&_*]:tw-text-black">
			<div className="tw-p-[0px] tw-h-full tw-w-full tw-font-quaternary tw-font-quaternary-ultra tw-flex tw-flex-col">
				<div className="tw-flex tw-justify-start tw-w-full">
					<AspectImage className="tw-w-full tw-max-w-[288px]" src={ Collection === "Power" ? `/images/rvmp-logo_with_flex-power.svg` : `/images/rvmp-logo_with_flex-temp.svg` } aspect={ 295 / 72 } />
				</div>
				<div className="
					[&>div]:tw-flex [&>div]:tw-items-baseline [&>div]:tw-space-x-[9px] [&>div>span:first-child]:tw-min-w-[95px] [&>div>span:first-child]:tw-flex-shrink-0
					tw-flex-grow tw-items-left tw-justify-center  tw-flex tw-flex-col tw-space-y-[9px] tw-pt-[9px] tw-text-[16.5px] tw-leading-none">
					<div>
						<span className="tw-font-quaternary-normal tw-text-[16px]">PO #:</span>
						<span className="tw-text-[16.1px]">
							{ ID }
						</span>
					</div>
					<div>
						<span className="tw-font-quaternary-normal tw-text-[16px]">OEM:</span>
						<span className="tw-text-[16.1px] tw-leading-[.9]">
							{ OEM }
						</span>
					</div>
					<div>
						<span className="tw-font-quaternary-normal tw-text-[16px]">Ship Date:</span>
						<span className="[&>span]:tw-font-quaternary-normal tw-text-[16.1px]" dangerouslySetInnerHTML={{ __html:  wrapText(Delivery_Date, '/', 'span') }}/>
					</div>

					{ Quantity && <>
						<div>
							<span className="tw-font-quaternary-normal tw-text-[16px]">Total Items:</span>
							<span className="tw-text-[16.1px] tw-leading-[.9]">
								{ Quantity }
							</span>
						</div>
					</> }
					{ Model && <>
						<div>
							<span className="tw-font-quaternary-normal tw-text-[16px]">Model:</span>
							<span className="tw-text-[16.1px] tw-leading-[.9]">
								{ Model }
							</span>
						</div>
					</> }
				</div>
				<div className="tw-w-full tw-mt-auto tw-text-right tw-text-[32px] tw-flex tw-items-baseline tw-justify-end tw-leading-none">
					<span><span className="tw-font-quaternary-normal tw-text-[16.5px]">Box:</span> { index + 1 } <span className="tw-font-quaternary-normal tw-text-[16.5px]">of</span> { Number_Items }</span>
				</div>
			</div>
		</div>
	</>
}

export default function OrderLabelPrint() {
	const [label, setLabel] = useState(null);
	const { orderLabels } = useAppContext();
	const { ID } = useParams();


	useEffect(() => {
		if (label) {
			setTimeout(() => {
				window.print();
				setTimeout(() => {
					window.history.back();
				}, 100);
			}, 100);
		}
	}, [label]);

	useLayoutEffect(() => {
		if (orderLabels && ID) {
			console.log('orderLabels:', orderLabels);
			console.log(getObjectByIndex(orderLabels, ID));
			setLabel(getObjectByIndex(orderLabels, ID))
		}
	}, [orderLabels, ID]);

	useLayoutEffect(() => {
		// window.scrollTo(0, 0)
	}, [label]);


	return label ? <>
		{/* <button className="button" onClick={handleGeneratePdf}>
			Download
		</button> */}
		<div>
			{[...Array(Number(label.Number_Items))].map((item, index) => {
				return <div key={`wrapper_print_${index}`} className="c-print-item tw-text-black tw-bg-white tw-relative tw-text-[14pt] tw-font-sans xtw-w-[432px] tw-w-full tw-h-[288px]" style={{ 'pageBreakAfter': 'always' }}>
					{/* PO: { label.ID }
					<br />
					OEM: { label.OEM } */}
					<Card index={index} ID={label.ID} OEM={label.OEM} Delivery_Date={label.Delivery_Date} Number_Items={label.Number_Items} Collection={ label.Collection } Model={ label.Model || null } Quantity={ label.Quantity || null} />
				</div>
			})}
		</div>

		<style>
			{`
				/*@page {
					size: C6;
					margin: 0;
				}*/

				.c-print-item {
					'page-break-after': 'always';
					page-break-after: always;
				}

				@media print {
					* {
						/*box-sizing: content-box;*/
						border: none;
					}
					header {
						display: none;
					}
					body, html, main {
						background: transparent !important;
						margin: 0 !important;
						padding: 0 !important;
						height: auto !important;
						min-height: 0px !important;
						max-height: none !important;
					}
					.c-print-item {
						page-break-after: always;
					}
				}
			`}
		</style>
	</> : <>
		Loading...
	</>
}
