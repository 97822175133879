
export const isNull = val => val === null || val === undefined || val.replaceAll(" ","") === "";

export const formatNumber = (number) => {
	if (!number) return;
	if (number === "-") return number;
	return new Intl.NumberFormat().format(Number(number));
}

export const digitsOnly = (value, enableNegative = true, errorReturn = 0, enableDecimals = true) => {
	let temp;
	if (!value) return;
	if (enableNegative && value === "-") {
		temp = "-";
	} else {
		if (enableDecimals) {
			temp = enableNegative ? value.replace(/[^-?\d.]/g, "") : value.replace(/[^?\d.]/g, "")
		} else {
			temp = enableNegative ? value.replace(/[^-?\d]/g, "") : value.replace(/[^\d]/g, "")
		}
	}

	return isNaN(Number(temp)) ? value : temp;
}

export const formatPhoneNumber = value => {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = digitsOnly(value);

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6, 10)}`;
}

export const formatPhone = field => {
	field.value = formatPhoneNumber(field.value);
}

export const replaceChar = (string, replace = '#', value = '.') => {
	if (!string) return;
	return string.replaceAll(replace, value)
}

export const isDate = (date) => (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));


export const formatDate = (date, format = "mmddyyyy", seperator="/") => {
	if (!date) return;
	// const d = isDate(date) ? date : new Date(date);
	const d = new Date(date);
	const year = d.getFullYear();
	const month = (d.getMonth() + 1) < 10 ? `0${d.getMonth() + 1}` : (d.getMonth() + 1);
	const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
	switch (format) {
		case 'yyyymmdd':
			return `${year}${seperator}${month}${seperator}${day}`;
		default:
			return `${month}${seperator}${day}${seperator}${year}`;
	}

}


export const formatDateForInput = (value, seperator = '/') => {
	if (!value) {
		return {
			"mmddyyyy": "",
			"yyyyddmm": "",
			"digits": "",
			"value":  "",
		}
	}

	if (value) {
		const digits = digitsOnly(value);
		const digitsLength = digits.length;

		if (value && value !== "" && (value && value.length < 2)) {

			return {
				"mmddyyyy": value ? digitsOnly(value) : "",
				"yyyyddmm": value ? digitsOnly(value) : "",
				"digits": value ? digitsOnly(value) : "",
				"value": value ? digitsOnly(value) : "",
			}
		}


		if (digitsLength < 3) return {
			"mmddyyyy": digits,
			"yyyyddmm": digits,
			"digits": digits || '0',
			"value": value || ''
		};

		if (digitsLength < 5) {
			return {
				"mmddyyyy": `${digits.slice(0, 2)}${seperator}${digits.slice(2, 4)}`,
				"yyyyddmm": `${digits.slice(2, 4)}${seperator}${digits.slice(0, 2)}`,
				"digits": digits || '0',
				"value": value || ''
			}
		}

		return {
			"mmddyyyy": `${digits.slice(0, 2)}${seperator}${digits.slice(2, 4)}${seperator}${digits.slice(4, 8)}`,
			"yyyyddmm": `${digits.slice(4, 8)}${seperator}${digits.slice(2, 4)}${seperator}${digits.slice(0, 2)}`,
			"digits": digits || '0',
			"value": value || ''
		};
	}
}



export const verifyField = (field, form = null) => {
	let isValid = field.checkValidity();

	// console.log(field, isValid)

	if (isValid) {
		field.classList.remove('invalid');
		field.classList.add('valid');
	} else {
		field.classList.remove('valid');
		field.classList.add('invalid');
	}

	if (field.required && isNull(field.value)) {
		isValid = false;
		field.classList.add('invalid');
	}

	if (form) verifyForm(document.querySelector(form));
}


export const verifyForm = (form, initialLoad = false) => {
	if (!form) return;
	let isValid = form.querySelectorAll('.invalid').length === 0;

	if (initialLoad) {
		isValid = false;
	} else {
		const fields = form.querySelectorAll('input, textarea, select');

		fields.forEach( field => verifyField(field) )

		isValid = form.querySelectorAll('.invalid').length === 0;

		if (isValid) {
			form.classList.add('valid');
			form.classList.remove('invalid');
		} else {
			form.classList.add('invalid');
			form.classList.remove('valid');
		}
	}

	return isValid;
}


export const stripHtml = (html) => {
	if (!html) return;
	return html.replace(/<[^>]*>?/gm, '');
}

export const nameInitials = (name, splitter='') => {
    let nameArray = name.split(' ');
    let initials = nameArray.map((name) => {
        return name[0].toUpperCase();
    });
    return initials.join(splitter);
 }


 export const removeSpecialCharacters = (string, keepFormat = false) => {
	if (!string) return;
	return keepFormat ? string.replace(/[^\w\s]/gi, '').replaceAll(' ', '_') : string.replace(/[^\w\s]/gi, '').replaceAll(' ', '_').toLowerCase();
}


export const formatUppercase = (string) => {
	if (!string) return;
	const temp = (typeof string === 'object' && string.value) ? string.value : string;
	return temp.toUpperCase();
}
