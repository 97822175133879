export const fieldDataFSUnits = [
	{
		Section: "Overview",
		Progress: "1",
		Description: "Overview of unit",
		items: [
			{ name: "Unit Name", description: "Provide unit name" },
			{ name: "Model", description: "Model of the unit", type:"select-model"},
			{ name: "VIN", description: "Provide VIN number" },
		],
	},
	{
		Section: "Cameras",
		Progress: "0.75",
		Description: "Details about the cameras",
		items: [
			{ name: "Number of Cameras", description: "Total number of cameras", type:"number-of-cameras", selectList:["One (1)", "Two (2)", "Three (3)"]},
			{},
			{ name: "Camera 1 Name", description: "Name of the first camera" },
			{
				name: "Camera 1 Make",
				description: "Make and model of the first camera",
			},
			{ name: "Camera 1 Serial Number", description: "Serial number of the first camera" },
			{ name: "Camera 1 IP Address", description: "IP address of the first camera", disabled: [{ disabled: "true" }, { defaultValue: "192.168.1.101" }] },
			{ name: "Camera 1 MAC Address",  capitalize: true, description: "MAC address of the first camera" },
			{},
			{ name: "Camera 1 Username", description: "Username for the first camera", type:"passwordToggle", textInputValue: "admin" },
			{ name: "Camera 1 Password", description: "Password for the first camera", type:"passwordToggle", defaultValue: "Flex247!" },
			{},
			{ name: "Camera 2 Name", description: "Name of the second camera" },
			{ name: "Camera 2 Make", description: "Make and model of the second camera" },
			{ name: "Camera 2 Serial Number", description: "Serial number of the second camera" },
			{ name: "Camera 2 IP Address", description: "IP address of the second camera", disabled: [{ disabled: "true" }, { defaultValue: "192.168.1.102" }]  },
			{ name: "Camera 2 MAC Address",  capitalize: true, description: "MAC address of the second camera" },
			{},
			{ name: "Camera 2 Username", description: "Username for the second camera", type:"passwordToggle", textInputValue: "admin" },
			{ name: "Camera 2 Password", description: "Password for the second camera", type:"passwordToggle", defaultValue: "Flex247!" },
			{},
			{ name: "Camera 3 Name", description: "Name of the third camera" },
			{ name: "Camera 3 Make", description: "Make and model of the third camera" },
			{ name: "Camera 3 Serial Number", description: "Serial number of the third camera" },
			{ name: "Camera 3 IP Address", description: "IP address of the third camera", disabled: [{ disabled: "true" }, { defaultValue: "192.168.1.103" }]  },
			{ name: "Camera 3 MAC Address",  capitalize: true, description: "MAC address of the third camera" },
			{},
			{ name: "Camera 3 Username", description: "Username for the third camera", type:"passwordToggle",  textInputValue: "admin" },
			{ name: "Camera 3 Password", description: "Password for the third camera", type:"passwordToggle", defaultValue: "Flex247!" },
		],
	},
	{
		Section: "Speaker",
		Progress: "0.4",
		Description: "Details about the speaker",
		items: [
			{ name: "Includes Speaker", description: "Whether the unit includes a speaker",  type:"includes-speakers", selectList:["Yes", "No"]},
			{ name: "Speaker Name", description: "Name of the speaker" },
			{ name: "Speaker Make", description: "Make of the speaker" },
			{ name: "Speaker Model", description: "Model of the speaker", disabled: [{ disabled: "true" }, { defaultValue: "SN-PA1H01-M-30" }]  },
			{},
			{ name: "Speaker IP Address", description: "IP address of the speaker", disabled: [{ disabled: "true" }, { defaultValue: "192.168.1.22" }]  },
			{ name: "Speaker MAC Address",  capitalize: true, description: "MAC address of the speaker" },
			{},
			{ name: "Speaker Username", description: "Username for the speaker", type:"passwordToggle",  textInputValue: "admin" },
			{ name: "Speaker Password", description: "Password for the speaker", type:"passwordToggle", defaultValue: "Flex247!", },
		],
	},
	{
		Section: "Victron",
		Progress: "0.00",
		Description: "Details about the Victron device",
		items: [
			{ name: "Victron Model", description: "Model of the Victron device", default: "Victron", required: true},
			{ name: "Victron Serial Number", description: "Serial number of the Victron device" },
			{ name: "Victron PIN", description: "PIN for the Victron device", type:"passwordToggle" },
		],
	},
	{
		Section: "Generator",
		Description: "Details about the generator",
		items: [
			{ name: "Generator Model", description: "Model of the generator" },
			{ name: "Generator Serial Number", description: "Serial number of the generator" }
			// { name: "Generator Model", description: "Model of the generator", type: "multiselect", options: ["2800i", "4000i", "5500i"] },
			// { name: "Generator Serial Number", description: "Serial number of the generator" }
		],
	},
	{
		Section: "Router",
		Description: "Details about the router",
		items: [
			{ name: "Router WiFi Name", description: "WiFi name of the router" },
			{ name: "Router Make", description: "Make of the router", disabled: [{ disabled: "true" }, { defaultValue: "Teltonika" }] },
			{ name: "Router Model", description: "Model of the router", disabled: [{ disabled: "true" }, { defaultValue: "RUTM50" }] },
			{ name: "Router Serial", description: "Serial Number of the router", disabled: [{ disabled: "true" }, { defaultValue: "RUTM50" }] },
			{ name: "Router Default Password", type:"passwordToggle", description: "The initial password for the router" },
			{},
			{ name: "Router Username", description: "Username for the router", textInputValue: "admin" },
			{ name: "Router Password", description: "Password for the router", type:"passwordToggle", defaultValue: "SecurityMobile247!" },
			{},
			{ name: "Router IP Address", description: "IP address of the router", disabled: [{ disabled: "true" }, { defaultValue: "192.168.1.1" }] },
			{ name: "Router Gateway", description: "Gateway address of the router", disabled: [{ disabled: "true" }, { defaultValue: "RUTM50" }] },
			{ name: "Router MAC Address",  capitalize: true, description: "MAC address of the router" },
			{ name: "Router Subnet Mask", description: "Subnet Mask address of the router", disabled: [{ disabled: "true" }, { defaultValue: "255.255.255.0" }] },
			{},
			{ name: "WiFi 5Ghz", description: "Name of WiFi 5Ghz" },
			{ name: "WiFi 5Ghz Password", description: "Password of WiFi 5Ghz", type:"passwordToggle", textInputValue: "SecurityMobile247!" },
			{ name: "WiFi 2.4Ghz", description: "Name of WiFi 5Ghz" },
			{ name: "WiFi 2.4Ghz Password", description: "Password of WiFi 5Ghz", type:"passwordToggle", textInputValue: "SecurityMobile247!" },
			{},
			{ name: "Router RMS URL", description: "Remote management system for router", type: "url" },
			{},
			{ name: "Router IMEI", description: "Router/Modem IMEI" },
		],
	},
	{
		Section: "VMS",
		Description: "Video monitoring system details and hardware.",
		items: [
			{ name: "VMS Make", description: "Make of the VMS", disabled: [{ disabled: "true" }, { defaultValue: "CVR" }] },
			{ name: "VMS Model", description: "Model of the VMS", disabled: [{ disabled: "true" }, { defaultValue: "Beelink MINI S" }] },
			{ name: "VMS Name", description: "Name of VMS in software" },
			{ name: "VMS MAC Address",  capitalize: true, description: "MAC address of the VMS" },
		],
	},
	{
		Section: "IOT Devices",
		Description: "IOT Devices",
		items: [
			{ name: "Shelly Model", description: "IOT relay for CVR", disabled: [{ disabled: "true" }, { defaultValue: "SNSW-001X16EU" }] },
			{ name: "Shelly Name", description: "Name of the Shelly device in the RMS" },
			{ name: "Shelly Device Id", description: "Name of the Shelly device in the RMS" }
		],
	},
	{
		Section: "Data Service",
		Description: "Details about the mobile data slots",
		items: [
			{ name: "Mobile Slot 1 Make", description: "Make of the first mobile slot", disabled: [{ disabled: "true" }, { defaultValue: "T-Mobile" }] },
			{ name: "Mobile Slot 1 OMEI", description: "OMEI of the first mobile slot" },
			{},
			{ name: "Mobile Slot 2 Make", description: "Make of the second mobile slot", disabled: [{ disabled: "true" }, { defaultValue: "Verizon" }] },
			{ name: "Mobile Slot 2 OMEI", description: "OMEI of the second mobile slot" },
		],
	},
];
