import { AppProvider, useAppContext } from "./contexts/app";
import { AuthProvider } from "./contexts/auth";
import { useEffect } from "react";
import { AuthenticatedRoute }from "./components/authenticated-route";
import Base from "./components/globals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./app/dashboard/home/page";
import Inventory from "./app/dashboard/inventory/page";
import Login from "./app/dashboard/login/page";
import WeightDims from "./app/dashboard/weight-dims/page";
import OrderLabel from "./app/dashboard/order-label/page";
import OrderLabelPrint from "./app/dashboard/order-label/print";
import QC, { QCCompletedList } from "./app/dashboard/qc/page";
import QCPrint from "./app/dashboard/qc/print";
import FSHome from "./app/fs/home/page";
import FSQC from "./app/fs/qc/page";
import FSUnit from "./app/fs/unit/page";
import FSInventory from "./app/fs/inventory/page";

function App() {

	return <>
		<AuthProvider>
				<AppProvider>
					<Router>
						<Base>
							<Routes>
								<Route path="/dashboard/qc/:ID" element={ <AuthenticatedRoute><QCPrint /></AuthenticatedRoute> } />
								<Route path="/dashboard/qc/print-all" element={ <AuthenticatedRoute><QCPrint /></AuthenticatedRoute> } />
								<Route path="/dashboard/qc/completed" element={ <AuthenticatedRoute><QCCompletedList /></AuthenticatedRoute> } />
								<Route path="/dashboard/qc/completed/serials" element={ <AuthenticatedRoute><QCCompletedList serials={ true } /></AuthenticatedRoute> } />
								<Route path="/dashboard/qc" element={ <AuthenticatedRoute><QC /></AuthenticatedRoute> } />
								<Route path="/dashboard/qc/edit" element={ <AuthenticatedRoute><QC /></AuthenticatedRoute> } />
								<Route path="/dashboard/inventory" element={<AuthenticatedRoute><Inventory /></AuthenticatedRoute>} />
								<Route path="/dashboard/weight-dims" element={<AuthenticatedRoute><WeightDims /></AuthenticatedRoute>} />
								<Route path="/dashboard/order-labels" element={<AuthenticatedRoute><OrderLabel /></AuthenticatedRoute>} />
								<Route path="/dashboard/order-labels/print/:ID" element={<AuthenticatedRoute><OrderLabelPrint /></AuthenticatedRoute>} />
								<Route path="/" element={<Home />} />
								<Route path="/login" element={<Login />} />
								<Route path="/fs" element={<AuthenticatedRoute><FSHome /></AuthenticatedRoute>} />
								<Route path="/fs/unit" element={<AuthenticatedRoute><FSUnit /></AuthenticatedRoute>} />
								<Route path="/fs/unit/:id" element={<AuthenticatedRoute><FSUnit /></AuthenticatedRoute>} />

								<Route path="/fs/qc" element={<AuthenticatedRoute><FSQC /></AuthenticatedRoute>} />
								<Route path="/fs/qc/:id" element={<AuthenticatedRoute><FSQC /></AuthenticatedRoute>} />
								<Route path="/fs/units" element={<AuthenticatedRoute><FSInventory /></AuthenticatedRoute>} />
								<Route path="/fs/inventory" element={<AuthenticatedRoute><FSInventory /></AuthenticatedRoute>} />
							</Routes>
						</Base>
					</Router>
				</AppProvider>
		</AuthProvider>
	</>;
}

export default App;
