import { Field, Input, Label } from "../../../components/forms";
import { ButtonPrimary } from "../../../components/ctas";
import { AspectImage } from "../../../components/assets";
import { ArticleHeader, PageHeader } from "../../../components/headers";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getProducts } from "../../../utils/api";
import { useAppContext } from "../../../contexts/app";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { digitsOnly, formatNumber, replaceChar, formatDate, formatDateForInput, verifyForm, verifyField } from "../../../utils/formatting";
import { useAuth } from "../../../hooks/useAuth";
import { getPropertyFromId, stringToArray, timeDiff, wrapText, wrapTextArray } from "../../../utils/global";

const InputRadios = (props) => {
	const [value, setValue] = useState('');

	const handleChange = e => {
		const temp = e.target.value;

		setValue(temp);

		if (props.setValues) {
			props.setValues({
				...props.values,
				"Collection": temp || "Power"
			});
		}

	}

	return <>
		<input type="hidden" name="Collection" className="tw-text-black" data-column="Collection" value={ value || "Power" } />
		<div className="tw-flex tw-gap-20px">
			<label className="tw-flex tw-gap-10px tw-cursor-pointer">
				<span className="tw-shrink-0">
					Flex Power<sup>&reg;</sup>
				</span>
				<input type="radio" name="option-1" value="Power" checked={ props.values ? props.values?.Collection === 'Power' : value === "Power" } onChange={ handleChange } />
			</label>
			<label className="tw-flex tw-gap-10px tw-cursor-pointer">
				<span className="tw-shrink-0">
					Flex Temp<sup>&trade;</sup>
				</span>
				<input type="radio" name="option-1" value="Temp" checked={ props.values ? props.values?.Collection === 'Temp' : value === "Temp" } onChange={ handleChange } />
			</label>
		</div>
	</>
}


const InputRadiosModel = (props) => {
	const [value, setValue] = useState('');
	const[isCustom, setIsCustom] = useState(false);

	const [data, setData] = useState(['2800i','2800e','4000i','4000e','5500i','5500e','Custom']);

	const handleChange = e => {
		const temp = e.target.value;

		if (temp === "Custom") {
			setIsCustom(true);
		} else {
			setValue(temp);

			if (props.setValues) {
				props.setValues({
					...props.values,
					"Model": temp || ""
				});
			}
		}

	}

	const handleUpdate = e => {
		const temp = e.target.value;

		setValue(temp);

		if (props.setValues) {
			props.setValues({
				...props.values,
				"Model": temp || ""
			});
		}

	}

	return <>
		<input type="hidden" name="Model" className="tw-text-black" data-column="Model" value={ value || "" } />
		<div className="tw-grid tw-grid-cols-2 tw-gap-20px">
			{ data.map((item, index) => {
				return <label key={`${item}_${index}`} className="tw-flex tw-gap-5px tw-cursor-pointer tw-items-center">
					<span className="tw-flex-1">
						{ item }
					</span>
					<input type="radio" name={`Model_${ index }`} value={ item } checked={ props.values ? props.values?.Model === item : value === item } onChange={ handleChange } />
				</label>
			})}
		</div>
		{ isCustom && <>
			<div className="tw-pt-15px">
				<Field type="tel" format="number" className="tw-flex-grow" required initValue={``} value={``} id={`model`} data-column="Model" label='<span>Custom Model Entry</span>' placeholder="#" onChange={handleUpdate} />
			</div>
		</> }
	</>
}

export default function OrderLabel() {
	const formRef = useRef(null);
	const { currentUser } = useAuth();
	const { setOrderLabels } = useAppContext();
	const [values, setValues] = useState({
		ID: "",
		OEM: "",
		Modified_Date: "",
		Modified_By: "",
		Delivery_Date: "",
		Number_Items: "",
		Collection: "Power",
		Model: "",
		Quantity: ""
	});
	const [init, setInit] = useState(null);
	const [items, setItems] = useState();
	const [itemsFormatted, setItemsFormatted] = useState({});

	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_ORDER_LABEL_SPREADSHEET_ID });

	const resetHandlerName = value => value.replaceAll(" ", "").replaceAll(" ", "_");

	const createRow = (values) => {
		return new Promise((resolve, reject) => {
			if (values.ID !== "") {

				addRow({
					'ID': values.ID,
					'OEM': values.OEM,
					'Modified Date': values.Modified_Date,
					'Modified By': values.Modified_By,
					'Delivery Date': values.Delivery_Date,
					'Number Items': values.Number_Items,
					'Collection': values.Collection,
					'Model': values.Model,
					'Quantity': values.Quantity
				});

				resolve(`"${values.ID}" Created`);

			} else {
				reject("No ID");
			}
		});
	}


	const handleUpdate = (event, save = false, target = null) => {
		const field = event && event.target;

		if (field) {
			const value = field.value;
			const isDate = field.getAttribute("format") === "date";
			const isNumber = field.getAttribute("format") === "number";
			const formatted = (value && value !== "") && isDate ? formatDateForInput(digitsOnly(value)).mmddyyyy : (value && value !== "") && isNumber ? digitsOnly(value) : value;

			field.value = formatted;

			if (formRef?.current && field) {
				verifyField(event.target, "#formRef");

				setValues({
					...values,
					[resetHandlerName(field.dataset.column)]: formatted
				});
			}
		}
	}


	// useEffect(() => {
	// 	console.log(values);
	// }, [values]);



	const handleSave = (e) => {
		if (e) e.preventDefault();
		const target = e.target;
		target.classList.add("_loading")

		const ID = document.querySelector(`input[data-column="ID"]`);
		const OEM = document.querySelector(`input[data-column="OEM"]`);
		const Delivery_Date = document.querySelector(`input[data-column="Delivery Date"]`);
		const Number_Items = document.querySelector(`input[data-column="Number Items"]`);
		const Collection = document.querySelector(`input[data-column="Collection"]`);
		const Quantity = document.querySelector(`input[data-column="Quantity"]`);
		const Model = document.querySelector(`input[data-column="Model"]`);

		const entries = {
			ID: ID.value,
			OEM: OEM.value,
			Modified_Date: new Date().toISOString(),
			Modified_By: `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			Delivery_Date: Delivery_Date.value,
			Number_Items: Number_Items.value,
			Collection: Collection.value,
			Quantity: Quantity.value,
			Model: Model.value,
		}

		setValues(entries);

		createRow(entries).then((data) => {
			console.log("Row Created", data);
			ID.disabled = true;
			OEM.disabled = true;
			Delivery_Date.disabled = true;
			Number_Items.disabled = true;
		})

		if (target) {
			setTimeout(() => {
				target.classList.remove("_loading")
				target.classList.add("_success");

				setTimeout(() => {
					target.classList.remove("_success")
					ID.value = "";
					OEM.value = "";
					Delivery_Date.value = "";
					Number_Items.value = "";

					ID.disabled = false;
					OEM.disabled = false;
					Delivery_Date.disabled = false;
					Number_Items.disabled = false;

					loadSheet(true).then((data) => {
						setInit(data)
					});

				}, 1000);
			}, 750);
		}
	}

	const stringifyFix = (obj) => {
		let cache = [];
		let str = JSON.stringify(obj, function (key, value) {
			if (typeof value === "object" && value !== null) {
				if (cache.indexOf(value) !== -1) {
					// Circular reference found, discard key
					return;
				}
				// Store value in our collection
				cache.push(value);
			}
			return value;
		});
		cache = null; // reset the cache
		return str;
	}

	const updateSheetRows = async () => {
		if (init) {
			const results = await sheetRows || null;

			if (results) setItems(results);

			return results;
		}
	}

	useEffect(() => {
		if (itemsFormatted) {
			setOrderLabels(itemsFormatted);
			// console.log(JSON.stringify(itemsFormatted, null, 4));
		}
	}, [itemsFormatted]);

	useEffect(() => {
		if (items) {
			const temp = items.reduce((acc, item) => {
				acc.push({
					"ID": item._rawData[0],
					"OEM": item._rawData[1],
					"Modified_Date": item._rawData[2],
					"Modified_By": item._rawData[3],
					"Delivery_Date": item._rawData[4],
					"Number_Items": item._rawData[5],
					"Collection": item._rawData[6],
					"Quantity": item._rawData[7],
					"Model": item._rawData[8]
				});
				return acc
			}, []);

			setItemsFormatted(temp.reverse());
		}
	}, [items]);

	useEffect(() => {
		if (init) updateSheetRows();
	}, [init]);

	useLayoutEffect(() => {
		loadSheet(true).then((data) => {
			setInit(data)
		});
	}, []);

	return <>
		<div className="tw-space-y-30px tw-pb-60px">
			<PageHeader className="tw-text-primary tw-w-full tw-text-center tw-leading-[.9]">
				<span className="tw-font-primary tw-font-primary-light">RVMP</span>{` `} Order Labels
			</PageHeader>
			<section>
				<div className="tw-w-full tw-min-h-screen tw-space-y-60px">

					<div className="tw-w-full tw-max-w-500px tw-p-20px md:tw-p-30px tw-rounded-12px tw-bg-[#292826] tw-border-1px tw-border-white/10 tw-shadow-sm tw-mx-auto">
						<form id="formRef" ref={formRef} onSubmit={handleSave} className="invalid validate || tw-flex tw-flex-col tw-w-full tw-gap-7px tw-space-y-20px md:tw-space-y-30px">
							<div>
								<div className="tw-grid tw-grid-cols-1 tw-gap-x-5px tw-gap-y-10px tw-grid-rows-2 tw-items-end tw-flex-grow">
									<div>
										<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`po_number`} data-column="ID" label='<span>PO Number</span>' placeholder="PO # or Invoices" onChange={handleUpdate} />
									</div>
									<div>
										<Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={`oem`} data-column="OEM" label='<span>OEM</span>' placeholder="Enter OEM Name" onChange={handleUpdate} />
									</div>
								</div>
								<div className="tw-pt-15px tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-10px tw-gap-y-10px tw-grid-rows-2 md:tw-grid-rows-1 tw-items-end tw-flex-grow">
									<div>
										<Field type="tel" format="date" maxLength="10" minLength="10" className="tw-flex-grow" required initValue={``} value={``} id={`deliver_date`} data-column="Delivery Date" label='<span>Delivery Date</span>' placeholder="MM/DD/YYYY" onChange={handleUpdate} />
									</div>
									<div>
										<Field type="tel" format="number" className="tw-flex-grow" required initValue={``} value={``} id={`number_items`} data-column="Number Items" label='<span>Number of Pallets</span>' placeholder="#" onChange={handleUpdate} />
									</div>
								</div>


								<div className="tw-pt-15px tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-10px tw-gap-y-10px tw-grid-rows-2 md:tw-grid-rows-1 tw-items-end tw-flex-grow">
									<div>
										<Field type="tel" format="number" maxLength="10" minLength="10" className="tw-flex-grow" required initValue={``} value={``} id={`quantity`} data-column="Quantity" label='<span>Quantity</span>' placeholder="#" onChange={handleUpdate} />
									</div>
								</div>

								<div className="tw-pt-15px">
									<Field className="tw-flex-grow" id={`Collection`} data-column="Collection" label='<span>Collection Type</span>'>
										<InputRadios setValues={ setValues } values={ values } />
									</Field>
								</div>

								{ values?.Collection === "Power" && <>
									<div className="tw-pt-15px">
										<Field className="tw-flex-grow" id={`Model`} data-column="Model" label='<span>Model</span>'>
											<InputRadiosModel setValues={ setValues } values={ values } />
										</Field>
									</div>
								</>}
							</div>
							<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-end">
								<ButtonPrimary className="c-submit-button tw-w-full md:tw-w-auto tw-transition-all tw-duration-300" default onClick={handleSave}>
									<span className="tw-inline-flex tw-text-4 tw-px-20px tw-py-5px tw-min-h-30px tw-min-w-120p tw-w-full tw-text-center tw-justify-center">
										<span>
											Create
										</span>
									</span>
								</ButtonPrimary>
							</div>
						</form>
					</div>

					{ itemsFormatted && itemsFormatted.length && <>
						<div className="tw-w-full tw-max-w-500px tw-rounded-12px tw-bg-[#292826] tw-border-1px tw-border-white/10 tw-shadow-sm tw-mx-auto">
							<div className="tw-divide-y-1px tw-divide-white/10 tw-flex tw-flex-col">
								{itemsFormatted.map((item, index) => {
									return <div key={`order_result_${ index }`} className="tw-w-full tw-flex tw-text-[.9em] tw-group hover:tw-bg-black/10 tw-p-20px md:tw-p-30px">
										<span className="tw-flex tw-flex-col tw-space-y-5px tw-w-full">
											<span className="tw-text-primary tw-font-primary-bold tw-w-full">
												{ item.OEM }
											</span>
											<span className="tw-text-white tw-font-primary-light tw-flex tw-gap-10px tw-w-full">
												<span>Delivery Date: { item.Delivery_Date } </span>
												<span>PO #: { item.ID }</span>
											</span>
											<div className="tw-ml-auto">
												<ButtonPrimary to={`/dashboard/order-labels/print/${ index }`} className="tw-transition-all tw-duration-300" default>
													<span className="tw-inline-flex tw-items-center tw-text-[.85em] tw-px-10px tw-py-0px tw-min-h-20px tw-text-center tw-justify-center">
														<span>
															Print
														</span>
													</span>
												</ButtonPrimary>
											</div>
										</span>
									</div>
								})}
							</div>
						</div>
					</>}
				</div>
			</section>
		</div>
	</>
}
