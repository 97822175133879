import { useState } from "react";
import { useAuth } from "./useAuth";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useAppContext } from "../contexts/app";
import _ from "lodash";

export const useGoogleSheet = ({ spreadsheetId, token }) => {
	const { currentUser, logout } = useAuth();
	const [spreadsheet, setSpreadsheet] = useState(null);
	const [sheetRows, setSheetRows] = useState(null);
	const { setRows } = useAppContext();

	const handleAuthError = (error, errorMessage = 'Google Auth Error') => {
		console.warn(errorMessage, error);

		// If the token is expired, navigate to the login page
		if (_.get(error, 'response.status') === 401) {
			logout();
		}
	};

	const loadSheet = async (returnItems, sheetIndex = 0) => {
		try {
			const doc = new GoogleSpreadsheet(spreadsheetId, { token: token || currentUser.credentials.access_token });

			// loads document properties and worksheets
			await doc.loadInfo();
			setSpreadsheet(doc);

			const sheet = doc.sheetsByIndex[sheetIndex];
			const rows = await sheet.getRows();

			setRows(rows.length);

			setSheetRows(rows);


			if (returnItems) {
				return rows;
			}
			// await doc.updateProperties({ title: 'renamed doc' });

			// const sheet = doc.sheetsByIndex[0]; // or use `doc.sheetsById[id]` or `doc.sheetsByTitle[title]`
			// console.log(sheet.title);
			// console.log(sheet.rowCount);

			// // adding / removing sheets
			// const newSheet = await doc.addSheet({ title: 'another sheet' });
			// console.log(doc);
		} catch (error) {
			handleAuthError(error, 'Error loading sheet as JSON');
		}
	}

	const loadSheetAsJson = async (returnItems) => {
		try {
			const doc = new GoogleSpreadsheet(spreadsheetId, { token: token || currentUser.credentials.access_token });
			await doc.loadInfo();
			const sheet = doc.sheetsByIndex[0];
			const rows = await sheet.getRows();
			setRows(rows);
			setSheetRows(rows);

			const headerValues = sheet._headerValues;
			console.log('headerValues', headerValues);

			if (returnItems) {
				return rows.map(row => {
					let jsonRow = {}
					headerValues.forEach(header => {
						jsonRow[header] = row.get(header);
					});
					return jsonRow;
				});
			}
		} catch (error) {
			handleAuthError(error);
		}
	}

	const addRow = async (row) => {
		try {
			const doc = new GoogleSpreadsheet(spreadsheetId, { token: token || currentUser.credentials.access_token });
			await doc.loadInfo();
			const sheet = doc.sheetsByIndex[0];
			const result = await sheet.addRow(row);

			loadSheet();
		} catch (error) {
			handleAuthError(error);
		}
	}

	const setRow = (row, header, value) => {
		try {
			row.set(header, value);
		} catch (error) {
			handleAuthError(error);
		}
	}

	const saveRow = (row) => {
		try {
			row.save();
		} catch (error) {
			handleAuthError(error);
		}
	}

	return { loadSheet, loadSheetAsJson, spreadsheet, sheetRows, setRow, saveRow, addRow };
};
