export const PDIchecklist = [
	{
		"group": "Camera Housing",
		"items": [
			{
				"category": "Housing",
				"items": [
					{
						"name": "Antenna",
						"description": "Correctly attached, sealed, and hooked-up",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Battery is connected",
						"description": "Battery is installed and connected",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Battery is fully charged",
						"description": "Battery is fully charges",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 1",
						"description": "Breaker 1 turns on/off tech equipment (POE, modem/antenna, CVR)",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 2",
						"description": "Breaker 2 turns on/off extra power to cameras",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 3",
						"description": "Breaker 3 turns on/off flood lights",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 4",
						"description": "Breaker 4 turns on/off strobe lights",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Cameras",
						"description": "Correctly secured to camera housing",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Flood lights relay",
						"description": "Test flood lights run within app",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Flood lights work",
						"description": "Flood lights run",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Horn",
						"description": "Correct hardware and properly installed",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "IOT Relay Works",
						"description": "Shelly IOT Relay works properly",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Power Connection",
						"description": "Power inlet correctly wired and mounted under housing",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Seal",
						"description": "bulb seal installed",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Wires secured",
						"description": "All wires are secured",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	},
	{
		"group": "Network",
		"items": [
			{
				"category": "Connection",
				"items": [
					{
						"name": "GPS",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Mobile Slot 1 and Slot 2 are on",
						"description": "turn on slot 1 and 2, with wifi as fallback",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "RMS",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Starlink",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "TMobile Activated",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "TMobile Tested",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "update time stamp on cameras",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Verizon Activated",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Verizon Tested",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "VRM",
						"description": "",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "WiFi",
						"description": "",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	},
	{
		"group": "Boxes",
		"items": [
			{
				"category": "Power Box",
				"items": [
					{
						"name": "Securement",
						"description": "Battery cables and wires correctly hooked up",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Battery Tray",
						"description": "Installed and secured",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Battery Cables",
						"description": "Landed and torqued to specified TQ (?)",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Solar Panel Wiring",
						"description": "Solar panel wiring terminated and connected",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Control Box",
						"description": "Installed and wiring routed correctly and secured",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Batteries are charged",
						"description": "Batteries are charged to a minimum of 12.5V",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 1",
						"description": "Breaker 1 turns on/off camera housing",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 2",
						"description": "Breaker 2 turns on/off AGS",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Breaker 3",
						"description": "Breaker 3 turns on/off all strobes for frame",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Charge Controller Breakers",
						"description": "Turns on/off charge controllers",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Components",
						"description": "Battery cables, gen harness, LP line, conduit wires correctly hooked up",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Power Disconnect",
						"description": "Turns on/off all components",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Shore power",
						"description": "Test that shore power works",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Solar Breaker",
						"description": "Turns on/off solar panels",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Latch",
						"description": "Locks and unlocks",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Securement Victron",
						"description": "victron unit, secured with properly",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Visual",
						"description": "No scratches, dents, or bubbles in graphicsAS",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Storage boxes",
				"items": [
					{
						"name": "Condition",
						"description": "No scratches,dings or marks",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Graphics",
						"description": "No scratches, bubbles and lined up",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "LP Box",
				"items": [
					{
						"name": "Securement",
						"description": "Correct hardware for box attachmenmt, tank rack,",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Latch",
						"description": "Locks and unlocks",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "LP Line",
						"description": "Correctly hook-up regulator to tanks and LP inlet",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Generator Box",
				"items": [
					{
						"name": "Generator Secured",
						"description": "generator secured in the box",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Generator",
						"description": "Test remote start and stop",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Generator Runs",
						"description": "Run test 30 secs",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Securement",
						"description": "Correct # of lags and screen secured",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Latch",
						"description": "Locks and unlocks",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Storage Box",
				"items": [
					{
						"name": "Latch",
						"description": "Locks and unlocks",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Firmware",
				"items": [
					{
						"name": "AGS Firmware",
						"description": "Updated AGS firmware in Power Box",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Cerbo GX Firmware",
						"description": "Updated Cerb GX firmware in Power Box",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Charge Controller Firmware",
						"description": "Updated both Charge Controllers have up to date firmware",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Inverter Firmware",
						"description": "Updated inverter firmware in Power Box",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	},
	{
		"group": "Frame",
		"items": [
			{
				"category": "Accesibility",
				"items": [
					{
						"name": "Keys",
						"description": "Box keys (2) camera housing (1) per unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Camera Mast Performance",
				"items": [
					{
						"name": "Fully Function",
						"description": "Extend and retract with crank or device",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Chassis",
				"items": [
					{
						"name": "Levels",
						"description": "installed on front and left side of chassis",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Harness Routing",
						"description": "Check harness for proper routing and verify lights",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Stabilizing Jack",
						"description": "Rotates and goes up and down",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Stabilizing Jack Extension Locking Pins",
						"description": "Legs extend and locking pins lock",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Tongue Jack",
						"description": "Goes up and down and swivles and locks in place",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Wheel Lug",
						"description": "Torqued to 80lb. spec with torch wrench",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Camera Mast, Solar Mast",
						"description": "Correct bolts installed",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Cord Reel",
						"description": "Correct hardware and properly attached",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Fenders",
						"description": "Securely attached",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Lights Blue white and brake",
						"description": "Check with a power source",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Step Tread",
						"description": "Rear bumper (2 pcs.) and spine(2 pcs.)",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Tongue Chains",
						"description": "check proper securemenmt of chains to frame",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Tongue Latch",
						"description": "check with 2\" ball",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Vin Tag",
						"description": "securely attached to the left front of the chassis",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Solar Rack",
				"items": [
					{
						"name": "Fully Function",
						"description": "extend and retract fully apply locking pins",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Solar Panels",
						"description": "secureded with proper hardware",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	},
	{
		"group": "Labels",
		"items": [
			{
				"category": "Brand",
				"items": [
					{
						"name": "All boxes branded",
						"description": "Boxes striped",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "FS logo",
						"description": "The \"FS\" logo added on front of camera mast",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Made in USA Left",
						"description": "Added to left side of camera mast",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Made in USA Right",
						"description": "Added to right side of camera mast",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Quality",
				"items": [
					{
						"name": "No bubble",
						"description": "No air bubble in decals",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "No misprint",
						"description": "No mispriints in decals",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Unit Stickers",
				"items": [
					{
						"name": "Camera Housing Label",
						"description": "Added inside the camera housing",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "CVR Label",
						"description": "Added inside the camera housing",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Unit Label",
						"description": "Added to the power box",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Warning Labels",
				"items": [
					{
						"name": "Do not overtighten",
						"description": "Added to the camera mast",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Do not stand",
						"description": "Added on Power Box and Gen Box",
						"required": true,
						"type": "checkbox"
					},
					{
						"name": "Propane",
						"description": "Added to propane box",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	},
	{
		"group": "Unit",
		"items": [
			{
				"category": "Boxes",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Camera Housing",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Camera Mast Visuals",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Cameras Packed",
				"items": [
					{
						"name": "Cameras are in Shipping Box",
						"description": "Cameras are disabled in Camera Box",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Decals",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Documentation",
				"items": [
					{
						"name": "Unit has all items documented",
						"description": "All items are documented in app",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Logistics",
				"items": [
					{
						"name": "Ready to ship",
						"description": "",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Netsuite",
				"items": [
					{
						"name": "Added to Netsuite",
						"description": "The VIN of unit added to Netsuite",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Solar Panels",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Solar Rack",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Test Run",
				"items": [
					{
						"name": "Properly Working",
						"description": "",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "VIN",
				"items": [
					{
						"name": "VIN Tag Added",
						"description": "VIN tag added on right side of unit",
						"required": true,
						"type": "checkbox"
					}
				]
			},
			{
				"category": "Wheels",
				"items": [
					{
						"name": "Visual Inspection",
						"description": "before unit is completed and signed off to customer, visual inspection over entire unit",
						"required": true,
						"type": "checkbox"
					}
				]
			}
		]
	}
];
